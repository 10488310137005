/*!
 * SurveyJS Creator v1.10.4
 * (c) 2015-2024 Devsoft Baltic OÜ - http://surveyjs.io/
 * Github: https://github.com/surveyjs/survey-creator
 * License: https://surveyjs.io/Licenses#SurveyCreator
 */
/* cyrillic-ext */

  
  /*# sourceMappingURL=fonts.fontless.css.map*//*!
   * SurveyJS Creator v1.10.4
   * (c) 2015-2024 Devsoft Baltic OÜ - http://surveyjs.io/
   * Github: https://github.com/surveyjs/survey-creator
   * License: https://surveyjs.io/Licenses#SurveyCreator
   */
  .svc-context-container {
    display: flex;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
    width: max-content;
  }
  
  .svc-context-button {
    display: block;
    box-sizing: border-box;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    border-radius: 50%;
    width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    cursor: pointer;
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    outline: none;
  }
  .svc-context-button use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .svc-context-button:hover use, .svc-context-button:focus use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-context-button--danger:hover use, .svc-context-button--danger:focus use {
    fill: var(--sjs-special-red, var(--red, #e60a3e));
  }
  svc-tab-json-editor-ace {
    width: 100%;
    height: 100%;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .svc-json-editor-tab__content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .svc-json-editor-tab__ace-editor {
    height: 100%;
  }
  svc-tab-json-editor-textarea {
    width: 100%;
    height: 100%;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .svc-json-editor-tab__content {
    position: relative;
    height: 100%;
  }
  
  .svc-json-editor-tab__content-area {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }
  
  .svc-json-editor-tab__errros_button {
    position: absolute;
    top: 0%;
    right: calc(0% + 2 * var(--sjs-base-unit, var(--base-unit, 8px)));
    min-height: calc(2.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
    max-height: calc(2.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
    border: none;
    background-color: var(--sjs-primary-backcolor, var(--primary, #293650));
    color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
    font-weight: bold;
  }
  
  .svc-json-editor-tab__content-errors {
    position: absolute;
    right: 0%;
    top: calc(0% + 3 * var(--sjs-base-unit, var(--base-unit, 8px)));
    max-width: calc(100% - 2 * var(--sjs-base-unit, var(--base-unit, 8px)));
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
    border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    border-radius: 1px;
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    font-family: monospace;
  }
  .svc-json-editor-tab__content-errors span {
    white-space: pre-line;
  }
  .svc-json-editor-tab__content-errors span > b {
    color: var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .svc-json-editor-tab__content-errors:empty {
    display: none;
  }
  
  .svc-json-errors {
    display: flex;
    max-height: calc(var(--sjs-base-unit, var(--base-unit, 8px)) * 25 + 5px);
    overflow-y: auto;
    flex-direction: column;
    padding: 0;
    margin: 0;
    border-top: 1px solid var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  }
  
  .svc-json-errors__item {
    pointer-events: none;
    display: block;
  }
  
  .svc-json-error {
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px)) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
    display: flex;
    align-items: center;
    gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-json-errors__item:not(:first-of-type) .svc-json-error {
    border-top: 1px solid var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  }
  
  .svc-json-error .sv-string-viewer {
    pointer-events: all;
    cursor: pointer;
  }
  
  .svc-json-error .sv-string-viewer:hover {
    text-decoration: underline;
  }
  
  .svc-json-error__icon {
    fill: var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .svc-json-error__container {
    display: flex;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-json-error__title {
    flex: 1 1 auto;
  }
  
  .svc-json-error__fix-button {
    appearance: none;
    flex-grow: 0;
    flex-shrink: 0;
    pointer-events: all;
    padding: 0 var(--sjs-base-unit, var(--base-unit, 8px));
    width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border: none;
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
    cursor: pointer;
  }
  .svc-json-error__fix-button svg {
    fill: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
    width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-json-error__fix-button:hover svg {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  .svd-simulator {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: content-box;
    transform-origin: 0 0;
    top: 50%;
    left: 50%;
    transform: scale(1.26) translate(-50%, -50%);
    overflow: overlay;
    border: 2px solid var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  }
  
  .svd-simulator-wrapper {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    border-radius: 32px;
    border: 4px solid var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1), 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  }
  
  .svd-simulator-main {
    min-height: 100%;
  }
  .svd-simulator-main.svd-simulator-main--desktop {
    min-height: auto;
  }
  .svd-simulator-main.svd-simulator-main--desktop.svd-simulator-main--running {
    height: 100%;
  }
  .svd-simulator-main.svd-simulator-main--frame {
    display: flex;
    align-items: center;
  }
  .svd-simulator-main:not(.svd-simulator-main--frame) {
    background-color: transparent;
  }
  .svd-simulator-main .sd-root-modern {
    min-height: 100%;
  }
  .svd-simulator-main .sd-root-modern .sv-popup:not(.sv-popup--overlay):not(.sv-popup--modal) {
    width: auto;
    right: 0;
  }
  
  .svd-simulator-content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -1px 0px 0px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
    position: relative;
  }
  
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    ::i-block-chrome,
    .svd-simulator-content {
      overflow-y: scroll;
    }
  }
  svc-tab-test {
    width: 100%;
    height: 100%;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .svc-test-tab__content .svc-plugin-tab__content {
    overflow-y: overlay;
  }
  .svc-test-tab__content .svc-plugin-tab__content .sv-root-modern .sv-completedpage,
  .svc-test-tab__content .svc-plugin-tab__content .sv_default_css .sv_completed_page {
    margin: 0;
    border: 0;
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  .svc-test-tab__content .svc-plugin-tab__content .sv_default_css .sv_body {
    border: 0;
  }
  .svc-test-tab__content .svc-plugin-tab__content .svc-preview__test-again {
    width: calc(33 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(12 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-top: 8px;
  }
  
  .svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content {
    height: calc(100% - 6 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  
  .svc-test-tab__content-actions {
    position: relative;
  }
  .svc-test-tab__content-actions .sv-action-bar {
    padding: 0;
    height: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border-top: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    width: 100%;
    position: absolute;
  }
  .svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages {
    left: 0;
    right: 0;
    justify-content: center;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages .sv-action__content {
    padding: 0;
  }
  .svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages .sv-action-bar-item {
    margin: 0;
  }
  .svc-test-tab__content-actions .sv-action-bar-item {
    --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.75 * var(--small-bold-font-size));
    line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
    width: 100%;
    height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-test-tab__content-actions .svc-page-selector {
    max-width: 50%;
  }
  .svc-test-tab__content-actions .svc-page-selector .sv-action-bar-item__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    display: inline-block;
  }
  
  .svc-test-tab__content .sd-body--empty {
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 400;
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    text-align: center;
    overflow: hidden;
    position: static;
    display: flex;
    flex-direction: column;
  }
  .sl-table {
    width: 50%;
    margin: 0 25%;
  }
  
  .sl-table__row {
    position: relative;
  }
  
  .sl-table__cell.st-table__cell--actions:first-of-type .sv-action-bar {
    margin-top: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sl-table .sl-table__detail-button.sl-table__detail-button {
    width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: 0;
  }
  .sl-table .sl-table__detail-button.sl-table__detail-button:hover:enabled, .sl-table .sl-table__detail-button.sl-table__detail-button.sv-focused--by-key {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
    outline: none;
  }
  .sl-table .sl-table__detail-button.sl-table__detail-button:hover:enabled use, .sl-table .sl-table__detail-button.sl-table__detail-button.sv-focused--by-key use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .sl-table__remove-button .sv-action-bar-item {
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    cursor: pointer;
  }
  .sl-table__remove-button .sv-action-bar-item:hover:enabled, .sl-table__remove-button .sv-action-bar-item.sv-focused--by-key {
    background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
    outline: none;
  }
  .sl-table__remove-button .sv-action-bar-item:hover:enabled use, .sl-table__remove-button .sv-action-bar-item.sv-focused--by-key use {
    fill: var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .sl-table__cell .sv-action-bar-item__icon {
    display: none;
  }
  
  .sl-table__row:hover .sl-table__cell .sv-action-bar-item__icon,
  .sl-table__cell .sv-action-bar-item.sv-focused--by-key .sv-action-bar-item__icon {
    display: block;
  }
  
  .sl-table__row #remove-row .sv-action-bar-item {
    border: none;
    width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .sl-table__row #remove-row .sv-action-bar-item__icon use {
    fill: var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .sl-table__row.sl-table__row--additional .sl-table__cell .svc-action-button {
    color: var(--sjs-secondary-backcolor, var(--secondary, #374462));
  }
  
  .sl-table__cell .svc-action-button,
  .sl-table__cell .svc-action-button:hover,
  .sl-table__cell .svc-action-button:focus,
  .sl-table__cell .svc-action-button:hover:enabled,
  .sl-table__cell .svc-action-button:focus:enabled {
    --medium-bold-font-size: var(--sjs-base-unit, var(--base-unit, 8px));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: bold;
    font-size: calc(3 * var(--medium-bold-font-size));
    line-height: calc(4 * var(--medium-bold-font-size));
    background: transparent;
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    outline: none;
    border: none;
    box-shadow: none;
    max-height: calc(13.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .sl-table__cell {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    font-weight: bold;
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    vertical-align: top;
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
    width: 100%;
  }
  
  .sl-table__cell--actions {
    position: absolute;
    width: max-content;
  }
  .sl-table__cell--actions:first-of-type {
    transform: translateX(-100%);
  }
  
  .sl-table__cell--detail-button {
    overflow: hidden;
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
  }
  
  .sl-table {
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
  }
  .sl-table thead th:first-child {
    position: absolute;
    left: 0;
    transform: translateX(-100%);
  }
  .sl-table thead th:last-child {
    position: absolute;
    right: 0;
    transform: translateX(100%);
  }
  .sl-table thead .sl-table__cell.st-table__cell--header {
    text-align: left;
  }
  .sl-table tbody tr:last-of-type .sl-table__cell {
    padding-bottom: calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .sl-table tr:first-of-type .sl-table__cell {
    padding-top: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .sl-table td:first-of-type,
  .sl-table th:first-of-type {
    padding-left: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .sl-table td:first-of-type .sv-action-bar,
  .sl-table th:first-of-type .sv-action-bar {
    justify-content: flex-end;
  }
  .sl-table td:last-of-type,
  .sl-table th:last-of-type {
    padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sl-panel .sl-panel__footer {
    padding: 0;
  }
  .sl-panel .sl-panel__footer .sv-action {
    width: 100%;
  }
  .sl-panel .sl-panel__footer .sv-action__content {
    width: 100%;
  }
  .sl-panel .sl-panel__footer button.sl-panel__done-button {
    width: 100%;
    margin: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
    color: var(--sjs-primary-backcolor, var(--primary, #293650));
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: flex;
    align-items: center;
    vertical-align: baseline;
    text-align: center;
    user-select: none;
  }
  .sl-panel .sl-panel__footer button:hover:enabled {
    background-color: var(--sjs-primary-backcolor, var(--primary, #293650));
    color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  }
  .sl-panel .sl-panel__footer .sv-action-bar-item__title {
    color: inherit;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    font-weight: 600;
  }
  
  .sl-element .sd-paneldynamic__buttons-container {
    padding: 0;
  }
  
  .sl-question {
    display: flex;
    align-items: center;
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sl-row {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
  }
  
  .sl-row--multiple .sl-panel {
    padding-bottom: 0;
  }
  
  .sl-question__header--left {
    margin-right: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .sl-row .sd-scrollable-container:not(.sd-scrollable-container--compact) {
    overflow-x: auto;
    padding: 2px;
    margin: -2px;
  }
  
  .sl-question__title {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin: 0;
    font-weight: 600;
  }
  .sl-question__title span + span {
    display: none;
  }
  
  .sl-row--multiple .sl-question {
    padding-right: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-logic-question-value {
    --sd-base-padding: calc(5 * var(--sjs-base-unit, var(--base-unit, 8px)));
    --sd-base-vertical-padding: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px)));
    --sd-page-vertical-padding: calc(3 * var(--sjs-base-unit, var(--base-unit, 8px)));
    padding-right: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-logic_trigger-editor {
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-logic_trigger-editor .svc-logic-question-value {
    margin-top: 0;
  }
  
  .sv-popup.sv-property-editor .sl-row {
    flex-flow: nowrap;
  }
  
  .spg-comment.sl-comment {
    height: calc(17 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sl-body .sd-row--fade-out,
  .sl-body .sd-row--fade-in {
    animation: none;
  }
  .sl-body .sd-element-wrapper--fade-out,
  .sl-body .sd-element-wrapper--fade-in {
    animation: none;
  }
  .sl-body .sd-element__content--fade-in,
  .sl-body .sd-element__content--fade-out {
    animation: none;
  }
  .sl-body .sd-paneldynamic__panel-wrapper--fade-in,
  .sl-body .sd-paneldynamic__panel-wrapper--fade-out,
  .sl-body .sd-paneldynamic__panel-wrapper--fade-out-left,
  .sl-body .sd-paneldynamic__panel-wrapper--fade-out-right,
  .sl-body .sd-paneldynamic__panel-wrapper--fade-in-left,
  .sl-body .sd-paneldynamic__panel-wrapper--fade-in-right {
    animation: none;
  }
  .sl-body .sd-table__row--fade-out,
  .sl-body .sd-table__row--fade-in {
    animation: none;
  }
  .svc-tab-translation {
    width: 100%;
    height: 100%;
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  .svc-tab-translation * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
  
  .st-properties {
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    flex-grow: 1;
    padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-left: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .st-properties .spg-table .spg-table__cell:first-of-type {
    width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .st-properties .spg-table .spg-table__question-wrapper {
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .st-properties .spg-table .spg-table__question-wrapper .spg-checkbox {
    margin: 0;
  }
  .st-properties .spg-table .spg-table__question-wrapper .spg-selectbase__label {
    gap: 0;
  }
  .st-properties .spg-table .spg-checkbox--disabled .spg-checkbox__rectangle,
  .st-properties .spg-table .spg-checkbox--disabled .spg-checkbox__rectangle:hover {
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .st-no-strings {
    --center-text-font-size: var(--sjs-base-unit, var(--base-unit, 8px));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 400;
    font-size: calc(2 * var(--center-text-font-size));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    overflow: hidden;
  }
  
  .st-content {
    display: flex;
    height: 100%;
  }
  
  .st-strings {
    height: calc(100% - 6 * var(--sjs-base-unit, var(--base-unit, 8px)));
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .st-strings-wrapper {
    flex-grow: 1;
  }
  
  .st-property-panel {
    flex-shrink: 0;
    flex-grow: 0;
    width: 450px;
    border-left: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  }
  
  .st-property-panel .spg-panel__content .spg-row {
    margin-top: 0;
  }
  
  .st-property-panel .spg-panel__content .spg-row:first-child {
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .st-strings-header .st-table__cell {
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    height: auto;
  }
  
  .st-root-modern {
    width: 100%;
  }
  
  .st-title.st-panel__title,
  .st-table__cell.st-table__cell--header {
    --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.75 * var(--small-bold-font-size));
    line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    text-align: left;
  }
  
  .st-title.st-panel__title {
    height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin: 0;
    background-color: var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9));
    border-bottom: 1px solid var(--sjs-border-light, var(--border-light, #eaeaea));
  }
  
  .st-table tr {
    display: flex;
    align-items: stretch;
  }
  
  .st-table__cell {
    display: block;
    flex: 1 1;
    box-sizing: border-box;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    border-bottom: 1px solid var(--sjs-border-light, var(--border-light, #eaeaea));
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
  }
  
  td.st-table__cell:first-of-type {
    --default-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
    line-height: calc(1.5 * var(--default-bold-font-size));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    max-width: 300px;
    padding-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  td.st-table__cell:first-of-type span {
    display: inline-block;
    padding-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .st-panel-indent .st-table__cell:first-of-type span {
    padding-left: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .st-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .st-comment {
    --default-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-size: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
    line-height: calc(1.5 * var(--default-font-size));
    display: block;
    width: calc(100% - 3 * var(--sjs-base-unit, var(--base-unit, 8px)));
    border: unset;
    outline: none;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    resize: none;
    padding: 0;
  }
  
  .sd-translation-line-skeleton {
    min-height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .st-navigation-btn {
    padding-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-translation-machine {
    margin-right: auto;
  }
  
  .svc-translation-machine-from {
    margin-right: left;
  }
  
  .st-body__footer {
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border-bottom: 1px solid var(--sjs-border-light, var(--border-light, #eaeaea));
  }
  
  .st-translation-dialog .sv-popup__body-header {
    margin-bottom: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .st-translation-dialog .st-root-modern > .sd-root-modern__wrapper {
    border-radius: 4px;
    box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
    overflow: hidden;
  }
  
  .st-container-modern .sv-components-column--expandable {
    width: auto;
  }
  
  .st-body .sd-row--fade-out,
  .st-body .sd-row--fade-in {
    animation: none;
  }
  .st-body .sd-element-wrapper--fade-out,
  .st-body .sd-element-wrapper--fade-in {
    animation: none;
  }
  .st-body .sd-element__content--fade-in,
  .st-body .sd-element__content--fade-out {
    animation: none;
  }
  .st-body .sd-paneldynamic__panel-wrapper--fade-in,
  .st-body .sd-paneldynamic__panel-wrapper--fade-out,
  .st-body .sd-paneldynamic__panel-wrapper--fade-out-left,
  .st-body .sd-paneldynamic__panel-wrapper--fade-out-right,
  .st-body .sd-paneldynamic__panel-wrapper--fade-in-left,
  .st-body .sd-paneldynamic__panel-wrapper--fade-in-right {
    animation: none;
  }
  .st-body .sd-table__row--fade-out,
  .st-body .sd-table__row--fade-in {
    animation: none;
  }
  .svc-tab-logic-edit {
    width: 100%;
    height: 100%;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  .svc-tab-logic-edit * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
  
  .svc-tab-logic-edit__content {
    height: calc(100% - 6 * var(--sjs-base-unit, var(--base-unit, 8px)));
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 16%;
    padding-right: 16%;
  }
  
  .svc-tab-logic-edit__content .sd-row {
    flex-wrap: wrap;
  }
  
  .svc-logic_trigger-editor .sd-row {
    margin-top: 0;
  }
  
  .svc-tab-logic-edit__content-actions .sv-action-bar {
    padding: 0;
    height: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border-top: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    width: 100%;
  }
  
  .svc-logic-paneldynamic div.svc-logic-operator {
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: content-box;
  }
  
  .svc-logic-paneldynamic .svc-logic-operator {
    -webkit-appearance: none;
    appearance: none;
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border: none;
    outline: none;
    font-weight: 600;
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    cursor: pointer;
  }
  .svc-logic-paneldynamic .svc-logic-operator option {
    background: var(--sjs-general-backcolor, var(--background, #fff));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  
  .svc-logic-operator:focus {
    outline: 1px dotted var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .sl-question.svc-logic-question--answered .svc-logic-operator {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  
  .svc-logic-operator.svc-logic-operator:hover, .svc-logic-operator.svc-logic-operator:focus {
    color: #ffffff;
    outline: none;
    box-shadow: none;
  }
  
  .svc-logic-operator.svc-logic-operator--question {
    background-color: var(--sjs-special-blue-light, var(--blue-light, rgba(67, 127, 217, 0.1)));
  }
  .svc-logic-operator.svc-logic-operator--question:hover, .svc-logic-operator.svc-logic-operator--question:focus {
    background-color: var(--sjs-special-blue, #437fd9);
  }
  
  .svc-logic-operator.svc-logic-operator--conjunction,
  .svc-logic-operator.svc-logic-operator--operator {
    background-color: var(--sjs-special-yellow-light, var(--yellow-light, transparent));
  }
  .svc-logic-operator.svc-logic-operator--conjunction:hover, .svc-logic-operator.svc-logic-operator--conjunction:focus,
  .svc-logic-operator.svc-logic-operator--operator:hover,
  .svc-logic-operator.svc-logic-operator--operator:focus {
    background-color: var(--sjs-special-yellow, var(--yellow, #374462));
  }
  
  .svc-logic-operator.svc-logic-operator--action {
    background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
  }
  .svc-logic-operator.svc-logic-operator--action:hover, .svc-logic-operator.svc-logic-operator--action:focus {
    background-color: var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .svc-logic-operator.svc-logic-operator--error {
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    color: var(--sjs-special-red, var(--red, #e60a3e));
    box-shadow: inset 0 0 0 2px var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .svc-logic-operator__error {
    display: none;
  }
  
  .svc-logic-paneldynamic .sd-paneldynamic__separator {
    display: none;
  }
  
  .svc-action-button.svc-logic-condition-remove.svc-icon-remove:focus {
    outline: none;
  }
  
  .svc-logic-condition-remove.svc-icon-remove {
    display: none;
    width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 4H20H16V2C16 0.9 15.1 0 14 0H10C8.9 0 8 0.9 8 2V4H4H2V6H4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V6H22V4ZM10 2H14V4H10V2ZM18 20H6V6H8H16H18V20ZM14 8H16V18H14V8ZM11 8H13V18H11V8ZM8 8H10V18H8V8Z' fill='%23E60A3E'/%3E%3C/svg%3E%0A");
    background-size: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-position: center;
  }
  
  .sl-panel-wrapper--in-row:hover .svc-logic-condition-remove.svc-icon-remove,
  .sl-panel-wrapper--in-row:focus-within .svc-logic-condition-remove.svc-icon-remove {
    display: block;
  }
  
  .svc-logic-condition-remove.svc-icon-remove:hover,
  .svc-logic-condition-remove.svc-icon-remove:focus {
    background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
  }
  
  .svc-logic-condition-remove-question {
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-logic-paneldynamic__button.svc-logic-paneldynamic__remove-btn {
    display: none;
  }
  
  .svc-logic-paneldynamic__button.svc-logic-paneldynamic__remove-btn {
    display: none;
  }
  
  .svc-logic-operator--action.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn,
  .svc-logic-operator--operator.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-logic-operator--action.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn:hover, .svc-logic-operator--action.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn:focus,
  .svc-logic-operator--operator.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn:hover,
  .svc-logic-operator--operator.sl-paneldynamic__add-btn.sl-paneldynamic__add-btn:focus {
    color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  }
  
  .svc-logic-tab__content.svc-logic-tab__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .svc-logic-tab__content.svc-logic-tab__empty .svc-logic-tab__content-action {
    width: calc(33 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-top: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-logic-tab__content .svc-logic-tab__content-action {
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-logic-tab__content .svc-logic-tab__content-action--disabled {
    cursor: default;
    outline: solid calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) transparent;
  }
  .svc-logic-tab__content .svc-logic-tab__content-action--disabled:focus, .svc-logic-tab__content .svc-logic-tab__content-action--disabled:hover {
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
  }
  .svc-logic-tab__content .svc-logic-tab__content-action--disabled .svc-text {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 0.25;
  }
  .svc-logic-tab__content .sd-paneldynamic__panel-wrapper--in-row {
    margin-top: 0;
    align-items: start;
  }
  
  .svc-logic-paneldynamic {
    font-family: var(--sjs-font-family, var(--font-family));
  }
  .svc-logic-paneldynamic .sd-row--multiple {
    padding: 0;
    background: none;
    box-shadow: none;
    border-radius: 0;
    overflow: visible;
  }
  .svc-logic-paneldynamic .sd-question__content {
    margin-top: 0;
  }
  .svc-logic-paneldynamic .sd-row__panel {
    width: calc(100% - 5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  
  .svc-logic-tab__content .svc-logic-paneldynamic .sd-row {
    margin-top: 0;
    flex-flow: row wrap;
  }
  
  .sl-table__cell--detail-panel .sd-body {
    margin: 0;
    min-width: none;
    max-width: none;
  }
  .sl-table__cell--detail-panel .sd-page.sd-body__page {
    padding: 0;
  }
  .sl-table__cell--detail-panel .sl-panel__footer {
    margin: 0;
  }
  
  .sl-dropdown {
    max-width: 45vw;
  }
  
  .sl-dropdown__value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .sl-list__item-body {
    padding-block: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-logic-placeholder {
    width: calc(78 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sv-popup--overlay .svc-logic-placeholder {
    width: initial;
  }
  
  .svc-logic-placeholder__text {
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 400;
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    text-align: center;
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    display: block;
    padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-logic-tab__leave-apply-button {
    background-color: var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .sl-list {
    padding-right: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-left: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .sl-list .sv-list__filter {
    padding-top: 0;
  }
  .sl-list .sv-list__filter-icon {
    top: calc(1.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  
  .sv-popup--dropdown-overlay .sv-list__filter {
    padding-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  svc-tab-designer {
    width: 100%;
    height: 100%;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .svc-tab-designer {
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow-y: scroll;
    position: static;
  }
  
  .svc-tab-designer--with-place-holder .svc-tab-designer_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .svc-tab-designer--with-place-holder .svc-designer-header {
    min-width: calc(84 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-tab-designer .svc-designer-header .sd-container-modern__title {
    background-color: transparent;
  }
  .svc-tab-designer .svc-tab-designer_content {
    width: 100%;
  }
  .svc-tab-designer .sd-title {
    display: flex;
  }
  .svc-tab-designer .sd-container-modern {
    min-width: calc(70 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
  }
  .svc-tab-designer .sd-container-modern.sd-container-modern--static {
    max-width: calc(84 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-tab-designer .sd-container-modern.sd-container-modern--responsive {
    max-width: initial;
  }
  .svc-tab-designer .sd-question.sd-question--image {
    width: 100%;
  }
  .svc-tab-designer .sd-progress,
  .svc-tab-designer .sd-progress__bar {
    background-color: transparent;
  }
  
  .svc-designer-header {
    border-bottom: 1px solid transparent;
    margin-top: 20px;
  }
  
  .svc-designer__placeholder-container {
    display: flex;
    flex-grow: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    text-align: center;
  }
  
  .svc-designer-placeholder-page {
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-designer-placeholder-page .svc-page__footer {
    width: calc(33 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin: auto;
  }
  
  .svc-designer-placeholder-page .svc-row--ghost {
    display: none;
  }
  .svc-creator--mobile .svc-tab-designer {
    overflow-y: overlay;
  }
  .svc-creator--mobile .sd-root-modern {
    --sd-base-padding: calc(3 * var(--sjs-base-unit, var(--base-unit, 8px)));
    --sd-base-vertical-padding: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
    --sd-page-vertical-padding: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  .svc-creator--mobile .svc-question__content .sd-question__content {
    margin-left: calc(-3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-right: calc(-3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-question__content .sd-question__content .sd-selectbase__column {
    max-width: initial;
    display: block;
    margin-left: 0;
  }
  .svc-creator--mobile .svc-question__content > .svc-question__dropdown-choices--wrapper {
    width: calc(100% + 6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin: 0 calc(-3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-question__content > .svc-question__dropdown-choices--wrapper > div {
    width: calc(100% - 6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin: 0 calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-question__content--selected .svc-rating-question-controls {
    bottom: calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-page {
    margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-creator--mobile .svc-page__content {
    padding-left: 0;
    padding-right: 0;
    border: 0;
  }
  .svc-creator--mobile .svc-page__content .sd-page__title {
    margin: 0;
  }
  .svc-creator--mobile .svc-page__content.svc-page__content--selected .sd-page__title {
    margin: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 0 0;
  }
  .svc-creator--mobile .svc-page__content .sd-page {
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-page__content .svc-page__footer {
    margin-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-question__content {
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-question__content.svc-question__content--selected {
    padding-top: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-bottom: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-tab-designer {
    justify-content: initial;
  }
  .svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title {
    padding: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title .svc-logo-image {
    margin-top: 0;
  }
  .svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title .svc-logo-image-placeholder {
    margin-left: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-top: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title h3 {
    margin: 0;
    font-size: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-tab-designer .sd-title.sd-container-modern__title h5 {
    margin: 0;
  }
  .svc-creator--mobile .svc-tab-designer .sd-container-modern {
    min-width: calc(43 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: 0;
  }
  .svc-creator--mobile .svc-properties-wrapper {
    width: 100%;
  }
  .svc-creator--mobile .svc-question__content-actions {
    width: calc(100% - 3 * var(--sjs-base-unit, var(--base-unit, 8px)));
    left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--mobile .svc-question__content-actions .sv-action-bar-item {
    border: 0;
  }
  .svc-creator--mobile .svc-page__content-actions .sv-action-bar {
    padding-right: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-creator--mobile .svc-page__content-actions .sv-action-bar .sv-action .sv-action__content {
    padding-right: 0;
  }
  .svc-creator--mobile .svc-page__content-actions .sv-action-bar .sv-action .sv-action__content .sv-action-bar-item__title--with-icon {
    display: none;
  }
  .svc-switcher.spg-action-button {
    align-items: center;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
    pointer-events: auto;
  }
  
  .svc-switcher__title {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-style: normal;
    font-weight: 600;
    line-height: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-switcher__icon {
    width: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    transition-duration: 0.2s;
    transition-property: background;
    transition-timing-function: linear;
  }
  
  .svc-switcher__icon:hover {
    background: var(--sjs-general-backcolor, var(--background, #fff));
  }
  
  .svc-switcher__icon:focus {
    border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #293650));
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .svc-switcher__icon:hover:focus {
    border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #293650));
    background: var(--sjs-general-backcolor, var(--background, #fff));
  }
  
  .svc-switcher__icon--checked,
  .svc-switcher__icon--checked:hover {
    justify-content: center;
    align-items: center;
    border: unset;
    border-radius: 100px;
    background-color: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-switcher__icon--checked:focus {
    border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #293650));
    background-color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  }
  
  .svc-switcher__icon-thumb {
    width: var(--sjs-base-unit, var(--base-unit, 8px));
    height: var(--sjs-base-unit, var(--base-unit, 8px));
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
    transform: translateX(0);
    margin-left: 0%;
    transition-duration: 0.2s;
    transition-property: margin-left, transform, background-color;
    transition-timing-function: linear;
  }
  
  .svc-switcher__icon--checked .svc-switcher__icon-thumb {
    background-color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
    margin-left: 100%;
    transform: translateX(-100%);
  }
  
  .svc-switcher__icon:focus .svc-switcher__icon-thumb,
  .svc-switcher__icon:hover:focus .svc-switcher__icon-thumb,
  .svc-switcher__icon--checked:focus .svc-switcher__icon-thumb {
    background-color: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  svc-tab-test {
    width: 100%;
    height: 100%;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .svc-test-tab__content .svc-plugin-tab__content {
    overflow-y: overlay;
  }
  .svc-test-tab__content .svc-plugin-tab__content .sv-root-modern .sv-completedpage,
  .svc-test-tab__content .svc-plugin-tab__content .sv_default_css .sv_completed_page {
    margin: 0;
    border: 0;
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  .svc-test-tab__content .svc-plugin-tab__content .sv_default_css .sv_body {
    border: 0;
  }
  .svc-test-tab__content .svc-plugin-tab__content .svc-preview__test-again {
    width: calc(33 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(12 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-top: 8px;
  }
  
  .svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content {
    height: calc(100% - 6 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  
  .svc-test-tab__content-actions {
    position: relative;
  }
  .svc-test-tab__content-actions .sv-action-bar {
    padding: 0;
    height: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border-top: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    width: 100%;
    position: absolute;
  }
  .svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages {
    left: 0;
    right: 0;
    justify-content: center;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages .sv-action__content {
    padding: 0;
  }
  .svc-test-tab__content-actions .sv-action-bar.sv-action-bar--pages .sv-action-bar-item {
    margin: 0;
  }
  .svc-test-tab__content-actions .sv-action-bar-item {
    --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.75 * var(--small-bold-font-size));
    line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
    width: 100%;
    height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-test-tab__content-actions .svc-page-selector {
    max-width: 50%;
  }
  .svc-test-tab__content-actions .svc-page-selector .sv-action-bar-item__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    display: inline-block;
  }
  .svc-notifier.svc-notifier--shown {
    visibility: visible;
    opacity: 0.75;
  }
  
  .svc-notifier {
    position: absolute;
    bottom: var(--sjs-base-unit, var(--base-unit, 8px));
    left: var(--sjs-base-unit, var(--base-unit, 8px));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    opacity: 0;
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    min-width: calc(30 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    text-align: center;
    z-index: 1600;
    visibility: hidden;
    --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.75 * var(--small-bold-font-size));
    line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  }
  
  .svc-notifier.svc-notifier--error {
    background-color: var(--sjs-special-red, var(--red, #e60a3e));
    color: #ffffff;
    opacity: 1;
  }
  
  .svc-creator--mobile .svc-notifier {
    left: 0;
    bottom: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: 100%;
    box-sizing: border-box;
    opacity: 1;
    z-index: 1000;
  }
  
  survey-creator,
  .svc-creator {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }
  @-moz-document url-prefix() {
    survey-creator,
    .svc-creator {
      scrollbar-width: thin;
      scrollbar-color: var(--sjs-border-default, var(--border, #d6d6d6)) transparent;
    }
  }
  survey-creator ::-webkit-scrollbar,
  .svc-creator ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: transparent;
  }
  survey-creator ::-webkit-scrollbar-thumb,
  .svc-creator ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 32px;
    background-color: var(--sjs-border-default, var(--border, #d6d6d6));
  }
  survey-creator ::-webkit-scrollbar-track,
  .svc-creator ::-webkit-scrollbar-track {
    background: transparent;
  }
  survey-creator ::-webkit-scrollbar-thumb:hover,
  .svc-creator ::-webkit-scrollbar-thumb:hover {
    border: 2px solid rgba(0, 0, 0, 0);
    background-color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .svc-creator__area {
    position: relative;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .svc-creator__content-wrapper {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }
  
  .svc-creator__content-holder {
    width: 100%;
    height: 100%;
  }
  
  .svc-creator-tab {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    min-height: var(--tab-min-height, auto);
  }
  
  .svc-creator__toolbox--right.svc-creator-tab {
    flex-direction: row-reverse;
  }
  
  .svc-creator-tab__content {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  
  .svc-plugin-tab__content {
    height: 100%;
    overflow-y: auto;
  }
  
  .svc-plugin-tab__content-actions sv-action-bar {
    padding: 0;
    height: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border-top: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  }
  
  .svc-creator__banner {
    width: 100%;
    line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    text-align: center;
  }
  
  .svc-item__banner {
    position: absolute;
    top: 0;
    max-width: 0;
    overflow: hidden;
    height: 100%;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    /* Shadow / Medium */
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
    transition: max-width 0.1s ease-in-out, padding 0.2s ease-in-out;
  }
  
  .svc-creator__non-commercial-text {
    color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
    --default-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
    line-height: calc(1.5 * var(--default-bold-font-size));
    vertical-align: middle;
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(16 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: block;
  }
  .svc-creator__non-commercial-text a {
    color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  }
  
  .svc-creator--mobile .svc-creator__non-commercial-text {
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sv-drag-drop-ghost {
    top: calc(0.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: 100%;
    height: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    position: absolute;
  }
  
  .sv-drag-drop-ghost--vertical {
    height: 10000px;
    width: 4px;
  }
  
  .sv-drag-drop-ghost--item-value-bottom {
    top: initial;
    bottom: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .sd-element__header .svc-string-editor {
    display: inline-block;
    max-width: 100%;
  }
  
  .svc-string-editor {
    position: static;
  }
  .svc-string-editor [contenteditable=true] {
    user-select: text;
    -webkit-user-select: text;
  }
  .svc-string-editor .sv-string-editor {
    position: relative;
    outline: none;
    z-index: 12;
    padding-bottom: 10px;
  }
  .svc-string-editor .sv-string-editor[aria-placeholder]:empty:before {
    content: attr(aria-placeholder);
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  .svc-string-editor .sv-string-editor--html,
  .svc-string-editor .sv-string-editor:focus-within {
    white-space: pre-wrap;
  }
  .svc-string-editor.svc-string-editor--hidden {
    display: none;
  }
  
  .svc-string-editor__content {
    display: inline-block;
    position: relative;
    z-index: 10;
    width: 100%;
  }
  
  .svc-matrix-cell .svc-string-editor__content {
    width: auto;
  }
  
  .svc-string-editor__content:focus-within .svc-remaining-character-counter {
    display: inline-block;
  }
  
  .svc-string-editor__input {
    display: flex;
  }
  
  .svc-remaining-character-counter {
    display: none;
    position: relative;
    outline: none;
    z-index: 20;
    padding: 0px;
    vertical-align: top;
    margin-inline-start: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-weight: 400;
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-string-editor__border {
    display: none;
    position: absolute;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: right;
    line-height: 0px;
  }
  
  .svc-string-editor__error {
    margin-left: var(--sjs-base-unit, var(--base-unit, 8px));
    line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: red;
    display: none;
  }
  
  .svc-string-editor__controls {
    position: absolute;
    left: 100%;
    height: 100%;
    width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    top: 0;
    padding-left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    z-index: 12;
    display: none;
    align-items: center;
    justify-content: center;
    line-height: 0px;
  }
  
  .svc-string-editor__button {
    display: none;
    position: relative;
  }
  .svc-string-editor__button use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-string-editor__button--edit {
    height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-string-editor__button--edit .sv-svg-icon {
    vertical-align: bottom;
  }
  .svc-string-editor__button--edit.sv-svg-icon {
    overflow: visible;
  }
  
  .svc-string-editor:hover:not(:focus-within) .svc-string-editor__button--edit {
    display: none;
  }
  
  .svc-string-editor:hover:not(.svc-string-editor--readonly):not(:focus-within) .svc-string-editor__border {
    display: flex;
    top: 0;
    bottom: 0;
    left: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    right: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border-radius: 3px;
    box-shadow: 0px 0px 0px calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) rgba(0, 0, 0, 0.16);
  }
  
  .svc-string-editor:focus-within .svc-string-editor__border {
    display: flex;
    top: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    bottom: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    left: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    right: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border-radius: 3px;
    box-sizing: content-box;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1)), 0px 0px 0px calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-string-editor.svc-string-editor--error:focus-within .svc-string-editor__border {
    box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1)), 0px 0px 0px calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .sd-boolean .sv-string-editor:focus-within {
    white-space: nowrap;
  }
  
  .sd-rating .sd-rating__item:focus-within {
    border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  .sd-rating .sd-rating__item:focus-within:not(.sd-rating__min-text) .svc-string-editor:focus-within .svc-string-editor__border, .sd-rating .sd-rating__item:focus-within:not(.sd-rating__max-text) .svc-string-editor:focus-within .svc-string-editor__border {
    display: none;
  }
  
  .sd-table__cell--actions .svc-string-editor__button--done,
  .sd-matrixdynamic__add-btn .svc-string-editor__button--done {
    width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sd-table__cell--column-title .svc-matrix-cell:not(:focus):focus-within .svc-matrix-cell--selected {
    border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #293650));
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: block;
  }
  .sd-table__cell--column-title .svc-string-editor:focus-within .svc-string-editor__border {
    display: none;
  }
  
  .sd-item__control-label .svc-string-editor:focus-within .svc-string-editor__border {
    left: calc(-0.7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    right: calc(-0.7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-string-editor:hover ~ .sd-question__required-text, .svc-string-editor:focus-within ~ .sd-question__required-text {
    visibility: hidden;
  }
  
  .svc-string-editor--multiline .sv-string-editor {
    white-space: pre-wrap;
  }
  .svc-btn {
    display: flex;
    align-items: center;
    height: calc(7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    vertical-align: baseline;
    text-align: center;
    background-color: var(--sjs-general-backcolor, var(--background, #374462));
    box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    cursor: pointer;
    user-select: none;
    outline: solid calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) transparent;
  }
  .svc-btn .svc-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    color: var(--sjs-primary-backcolor, var(--primary, #fff));
  }
  
  .svc-btn:hover {
    background-color: var(--sjs-questionpanel-hovercolor, var(--sjs-general-backcolor-dark, #293650));
  }
  
  .svc-btn:focus {
    box-shadow: 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-action-button {
    display: inline-block;
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-primary-backcolor, var(--primary, #293650));
    font-weight: 600;
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid transparent;
  }
  .svc-action-button:focus, .svc-action-button:hover {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
    outline: none;
  }
  
  .svc-action-button--selected {
    border-color: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-action-button--disabled {
    opacity: 0.25;
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  svc-page-navigator,
  .svc-page-navigator {
    display: flex;
    flex-direction: column;
  }
  
  .svc-page-navigator__navigator-icon {
    display: block;
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: calc(1.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border-radius: 50%;
    cursor: pointer;
  }
  .svc-page-navigator__navigator-icon use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .svc-page-navigator__selector {
    width: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    min-height: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  survey-creator .svc-page-navigator__selector:hover, survey-creator .svc-page-navigator__selector:focus,
  .svc-creator .svc-page-navigator__selector:hover,
  .svc-creator .svc-page-navigator__selector:focus {
    outline: none;
  }
  survey-creator .svc-page-navigator__selector:hover .svc-page-navigator__navigator-icon, survey-creator .svc-page-navigator__selector:focus .svc-page-navigator__navigator-icon,
  .svc-creator .svc-page-navigator__selector:hover .svc-page-navigator__navigator-icon,
  .svc-creator .svc-page-navigator__selector:focus .svc-page-navigator__navigator-icon {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  }
  survey-creator .svc-page-navigator__selector:hover .svc-page-navigator__navigator-icon use, survey-creator .svc-page-navigator__selector:focus .svc-page-navigator__navigator-icon use,
  .svc-creator .svc-page-navigator__selector:hover .svc-page-navigator__navigator-icon use,
  .svc-creator .svc-page-navigator__selector:focus .svc-page-navigator__navigator-icon use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-page-navigator__selector--opened .svc-page-navigator__navigator-icon {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
    opacity: 0.5;
  }
  .svc-page-navigator__selector--opened .svc-page-navigator__navigator-icon use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-page-navigator__popup {
    min-width: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-tab-designer--with-page-navigator .svc-tab-designer_content {
    margin-right: calc(6.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: calc(100% - 6.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-tab-designer__page-navigator {
    position: absolute;
    padding: calc(16.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
    top: 0;
    bottom: 0;
    right: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-page-navigator {
    width: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-creator__toolbox--right .svc-tab-designer--with-page-navigator .svc-tab-designer_content,
  [dir=rtl] .svc-tab-designer--with-page-navigator .svc-tab-designer_content,
  [style*="direction:rtl"] .svc-tab-designer--with-page-navigator .svc-tab-designer_content,
  [style*="direction: rtl"] .svc-tab-designer--with-page-navigator .svc-tab-designer_content {
    margin-right: 0;
    margin-left: calc(6.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator__toolbox--right .svc-tab-designer__page-navigator,
  [dir=rtl] .svc-tab-designer__page-navigator,
  [style*="direction:rtl"] .svc-tab-designer__page-navigator,
  [style*="direction: rtl"] .svc-tab-designer__page-navigator {
    right: unset;
    left: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-tab-designer--bypage-mode .svc-tab-designer_content {
    display: flex;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }
  .svc-tab-designer--bypage-mode .svc-tab-designer__page-navigator {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
  }
  svc-page-navigator-item,
  .svc-page-navigator-item {
    display: block;
    width: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    min-height: calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    cursor: pointer;
    position: relative;
  }
  
  .svc-page-navigator-item-content {
    height: 100%;
    width: 100%;
  }
  .svc-page-navigator-item-content:hover, .svc-page-navigator-item-content:focus {
    outline: none;
  }
  
  .svc-page-navigator__items--up .svc-page-navigator-item-content {
    transition: 0.25s ease-in-out;
    transform: translateY(-100%);
  }
  .svc-page-navigator__items--up svc-page-navigator-item:first-child .svc-page-navigator-item-content {
    opacity: 0;
  }
  
  .svc-page-navigator__items--down .svc-page-navigator-item-content {
    transition: 0.25s ease-in-out;
    transform: translateY(100%);
  }
  .svc-page-navigator__items--down svc-page-navigator-item:last-child .svc-page-navigator-item-content {
    opacity: 0;
  }
  
  .svc-page-navigator-item__dot {
    box-sizing: content-box;
    position: absolute;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    right: calc(50% - 3px);
    top: calc(50% - 3px);
    background: var(--sjs-border-default, var(--border, #d6d6d6));
  }
  
  .svc-page-navigator-item--selected .svc-page-navigator-item__dot {
    width: var(--sjs-base-unit, var(--base-unit, 8px));
    height: var(--sjs-base-unit, var(--base-unit, 8px));
    right: calc(50% - 1.5 * var(--sjs-base-unit, var(--base-unit, 8px)) / 2);
    top: calc(50% - 1.5 * var(--sjs-base-unit, var(--base-unit, 8px)) / 2);
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-page-navigator-item__banner {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    right: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: flex;
    align-items: center;
    line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    animation: 0.5s ease-in;
    padding: 0;
    opacity: 0;
    z-index: 20;
  }
  .svc-page-navigator-item__banner .svc-page-navigator-item__dot {
    position: absolute;
    display: inline-block;
    top: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    right: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-creator__toolbox--right .svc-page-navigator-item__banner,
  [dir=rtl] .svc-page-navigator-item__banner,
  [style*="direction:rtl"] .svc-page-navigator-item__banner,
  [style*="direction: rtl"] .svc-page-navigator-item__banner {
    right: unset;
    left: calc(0.625 * var(--base-unit, 8px));
  }
  .svc-creator__toolbox--right .svc-page-navigator-item__banner .svc-page-navigator-item__dot,
  [dir=rtl] .svc-page-navigator-item__banner .svc-page-navigator-item__dot,
  [style*="direction:rtl"] .svc-page-navigator-item__banner .svc-page-navigator-item__dot,
  [style*="direction: rtl"] .svc-page-navigator-item__banner .svc-page-navigator-item__dot {
    right: unset;
    left: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-page-navigator-item-content:not(.svc-page-navigator-item--disabled) .svc-page-navigator-item__banner .svc-page-navigator-item__dot {
    width: var(--sjs-base-unit, var(--base-unit, 8px));
    height: var(--sjs-base-unit, var(--base-unit, 8px));
    border: none;
    background: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
  .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner {
    padding: 0 calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    max-width: calc(25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    opacity: 1;
  }
  
  .svc-creator__toolbox--right .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
  .svc-creator__toolbox--right .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner,
  [dir=rtl] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
  [dir=rtl] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner,
  [style*="direction:rtl"] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
  [style*="direction:rtl"] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner,
  [style*="direction: rtl"] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover .svc-page-navigator-item__banner,
  [style*="direction: rtl"] .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus .svc-page-navigator-item__banner {
    padding: 0 calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-page-navigator-item--disabled .svc-page-navigator-item__banner {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 0.25;
  }
  svc-page {
    display: block;
  }
  
  .svc-page {
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-page .sd-page__title {
    margin: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 0 0;
  }
  
  .svc-page__content {
    position: relative;
    border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(0 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    width: 100%;
    outline: none;
  }
  .svc-page__content .sd-page {
    margin: 0;
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-page__content-actions {
    position: absolute;
    display: none;
    top: var(--sjs-base-unit, var(--base-unit, 8px));
    inset-inline-end: 0;
    padding: 0 calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    z-index: 1;
  }
  
  .svc-page__content:not(.svc-page__content--new):focus,
  .svc-hovered.svc-page__content:not(.svc-page__content--new) {
    background: var(--sjs-secondary-backcolor-semi-light, transparent);
  }
  
  .svc-creator .svc-page .svc-page__content--selected.svc-page__content--selected,
  .svc-creator .svc-page .svc-page__content--selected:focus.svc-page__content--selected,
  .svc-hovered.svc-page__content--selected {
    box-shadow: 0 0 0 2px inset var(--sjs-secondary-backcolor, var(--secondary, #374462));
    background: var(--sjs-secondary-backcolor-semi-light, transparent);
  }
  .svc-creator .svc-page .svc-page__content--selected.svc-page__content--selected .svc-page__content-actions,
  .svc-creator .svc-page .svc-page__content--selected:focus.svc-page__content--selected .svc-page__content-actions,
  .svc-hovered.svc-page__content--selected .svc-page__content-actions {
    display: block;
  }
  
  .svc-page__footer {
    overflow: visible;
    margin-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-page__footer .sv-action {
    flex: 1 1 0;
  }
  .svc-page__footer .svc-btn {
    flex-grow: 1;
    border: none;
  }
  
  .svc-page__add-new-question .svc-text {
    margin-left: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-page__add-new-question {
    -webkit-tap-highlight-color: transparent;
  }
  
  .svc-page__question-type-selector {
    appearance: none;
    display: flex;
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    margin-inline-end: var(--sjs-base-unit, var(--base-unit, 8px));
    outline: none;
  }
  .svc-page__question-type-selector use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  .svc-page__question-type-selector:hover {
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  .svc-page__question-type-selector:focus {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  }
  
  .svc-page--drag-over-empty:after {
    content: " ";
    position: absolute;
    background: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    bottom: calc(11.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: calc(100% - 48px);
    height: 2px;
  }
  
  .svc-page--drag-over-empty-no-add-button:after {
    bottom: auto;
  }
  
  .svc-page__placeholder_frame {
    min-width: auto;
    max-width: 100%;
    padding: 0 calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-page__placeholder_frame .svc-panel__placeholder_frame {
    padding: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: auto;
  }
  .svc-row {
    width: 100%;
    position: relative;
  }
  
  .svc-row:not(.svc-row--ghost) > .sd-row {
    min-height: 50px;
  }
  
  .svc-row > .sd-row.sd-page__row {
    margin-top: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  
  .svc-row .sd-row.sd-row--multiple {
    overflow: auto;
    width: calc(100% + 4 * var(--sjs-base-unit, var(--base-unit, 8px)));
    flex-wrap: nowrap;
    margin-top: 0;
    padding-top: calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
    padding-right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-bottom: 2px;
    margin-right: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-bottom: -2px;
  }
  .svc-row .sd-row.sd-row--multiple .sd-row--multiple {
    overflow: unset;
  }
  
  .sd-panel .svc-row .sd-row--multiple {
    padding: calc(0 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-shadow: none;
    border-radius: 0;
    padding: 2px;
    margin: -2px;
    width: 100%;
  }
  .sd-panel .svc-row .sd-row--multiple > div {
    padding: 0;
  }
  
  .svc-row.svc-row--ghost .svc-question__content {
    padding: 0;
    border: none;
    box-shadow: none;
  }
  .svc-row.svc-row--ghost .sd-row {
    height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-row.svc-row--ghost:last-child .sd-row {
    height: initial;
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-row.svc-row--ghost + .svc-row .sd-page__row {
    margin-top: 0;
  }
  
  .svc-panel .svc-row.svc-row--ghost.svc-row.svc-row--ghost .sd-row {
    height: initial;
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-row--drag-over-top:before {
    content: " ";
    position: absolute;
    left: 0;
    background: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    top: 6px;
    width: 100%;
    height: 2px;
  }
  
  .svc-row--drag-over-bottom:after {
    content: " ";
    position: absolute;
    left: 0;
    background: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    bottom: -10px;
    width: 100%;
    height: 2px;
  }
  
  .svc-question__content--panel .svc-row--drag-over-top:before,
  .sd-panel__content .svc-row--drag-over-top:before {
    top: -4px;
  }
  .svc-question__content--panel .svc-row--drag-over-bottom:after,
  .sd-panel__content .svc-row--drag-over-bottom:after {
    bottom: -4px;
  }
  .svc-row .sd-row--fade-out,
  .svc-row .sd-row--fade-in {
    animation: none;
  }
  .svc-row .sd-element-wrapper--fade-out,
  .svc-row .sd-element-wrapper--fade-in {
    animation: none;
  }
  .svc-row .sd-element__content--fade-in,
  .svc-row .sd-element__content--fade-out {
    animation: none;
  }
  .svc-row .sd-paneldynamic__panel-wrapper--fade-in,
  .svc-row .sd-paneldynamic__panel-wrapper--fade-out,
  .svc-row .sd-paneldynamic__panel-wrapper--fade-out-left,
  .svc-row .sd-paneldynamic__panel-wrapper--fade-out-right,
  .svc-row .sd-paneldynamic__panel-wrapper--fade-in-left,
  .svc-row .sd-paneldynamic__panel-wrapper--fade-in-right {
    animation: none;
  }
  .svc-row .sd-table__row--fade-out,
  .svc-row .sd-table__row--fade-in {
    animation: none;
  }
  .svc-carry-forward-panel-wrapper {
    width: 100%;
    container-type: inline-size;
  }
  
  .svc-carry-forward-panel {
    box-sizing: border-box;
    width: 100%;
    padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
    background-color: #F8F8F8;
    text-align: center;
    font-weight: 400;
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: rgba(0, 0, 0, 0.45);
  }
  
  @container (max-width: 176px) {
    .svc-carry-forward-panel {
      display: none;
    }
  }
  @container (max-width: 496px) {
    .svc-carry-forward-panel {
      padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    }
  }
  .svc-carry-forward-panel__link .svc-action-button {
    padding: 0;
    border: none;
    font-weight: inherit;
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
  .svc-carry-forward-panel__link .svc-action-button:hover, .svc-carry-forward-panel__link .svc-action-button:focus {
    background-color: transparent;
  }
  
  svc-question {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
  }
  
  .svc-question__adorner {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }
  .svc-question__adorner .sv-action-bar {
    overflow: visible;
  }
  .svc-question__adorner .sv-action-bar .sv-action:not(:first-child) .sv-action-bar-item {
    border: none;
  }
  .svc-question__adorner .sv-action-bar .sv-action:not(:first-child) .sv-action-bar-item:active {
    opacity: 0.5;
    background-color: var(--background-dim, #f3f3f3);
  }
  
  .svc-question__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: auto;
    flex-grow: 1;
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
    outline: none;
  }
  
  .svc-question__content .sd-selectbase:not(.sd-imagepicker) {
    padding-left: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-left: calc(-5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-question__content .sd-table .sd-selectbase:not(.sd-imagepicker) {
    overflow-x: visible;
    padding-left: 0;
    margin-left: 0;
  }
  .svc-question__content .sd-question.sd-question--table {
    overflow-x: initial;
  }
  .svc-question__content .sd-multipletext__item-title {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  .svc-question__content .sd-multipletext__item-container.sd-input:focus-within {
    box-shadow: var(--sjs-shadow-inner, inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
  }
  .svc-question__content .sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 0.25;
  }
  .svc-question__content .sjs_sp_placeholder {
    user-select: all;
    pointer-events: all;
  }
  
  .svc-question__content > div {
    overflow: visible;
  }
  
  .svc-question__content .sd-question:not(.sd-question--paneldynamic) .sd-panel__content {
    overflow: auto;
  }
  .svc-question__content .sd-question:not(.sd-question--paneldynamic) .sd-table__cell--detail-panel .sd-panel__content {
    overflow: unset;
  }
  
  .svc-panel__placeholder {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    text-wrap: wrap;
    text-align: center;
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-style: normal;
    font-weight: 400;
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-question__content-actions {
    position: absolute;
    opacity: 0;
    bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    inset-inline-start: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    inset-inline-end: calc(3.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-question__content-actions:focus-within {
    opacity: 1;
  }
  .svc-question__content-actions .sv-action--convertTo {
    max-width: max-content;
  }
  .svc-question__content-actions .sv-action--convertTo .sv-action-bar-item--icon {
    max-width: 100%;
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    justify-content: left;
  }
  .svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title::after {
    content: " ";
    display: inline-block;
    margin-inline-start: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    vertical-align: bottom;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 11L13 6H3L8 11Z' fill='%23374462'/%3E%3C/svg%3E%0A");
  }
  .svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__icon use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  .svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__icon:not(:last-child) {
    display: none;
  }
  .svc-question__content-actions .sv-action--convertTo .sv-action-bar-item__title--with-icon {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  .svc-question__content-actions .sv-action--convertTo.sv-action--hidden {
    min-width: 0;
  }
  .svc-question__content-actions .sv-action--convertTo-last {
    margin-inline-end: auto;
  }
  .svc-question__content-actions .svc-action-bar-item--right:first-of-type {
    margin-inline-start: auto;
  }
  .svc-question__content-actions .sv-dots {
    width: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-left: var(--sjs-base-unit, var(--base-unit, 8px));
    margin-right: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-question__content-actions .sv-dots.sv-action--hidden {
    width: 0;
    margin: 0;
  }
  .svc-question__content-actions .sv-dots.sv-action--hidden .sv-action__content {
    display: none;
  }
  .svc-question__content-actions .sv-dots .sv-dots__item {
    margin-left: 0;
  }
  .svc-question__content-actions .sv-dots__item use {
    fill: var(--sjs-secondary-backcolor, var(--secondary, #374462));
  }
  
  .svc-question__content:focus,
  .svc-hovered > .svc-question__content {
    box-shadow: 0 0 0 2px var(--sjs-secondary-backcolor-light, var(--secondary-light, #374462)));
  }
  
  .svc-question__content--selected:not(.svc-question__content--dragged) {
    box-shadow: 0 0 0 2px var(--sjs-secondary-backcolor, var(--secondary, #374462));
  }
  
  .svc-question__content--selected:not(.svc-question__content--dragged) > .svc-question__content-actions,
  .svc-creator:not(.svc-creator--mobile) .svc-page .svc-hovered > .svc-question__content:not(.svc-question__content--dragged) > .svc-question__content-actions {
    opacity: 1;
  }
  
  .svc-question__content--dragged {
    background: var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9));
  }
  .svc-question__content--dragged .sd-question__title {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  .svc-question__content--dragged.svc-question__content--selected .svc-question__content-actions {
    display: none;
  }
  
  .svc-question__content .sd-question.sd-question--table {
    margin-top: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-dragged-element-shortcut {
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-right: calc(3.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    cursor: grabbing;
    position: absolute;
    z-index: 1000;
    box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: content-box;
  }
  
  .svc-dragged-element-shortcut .sv-svg-icon use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-dragged-element-shortcut--selected {
    border: 2px solid var(--sjs-secondary-backcolor, var(--secondary, #374462));
  }
  
  .svc-dragged-element-shortcut__text {
    --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.75 * var(--small-bold-font-size));
    line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
    margin-left: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-dragged-element-shortcut__icon {
    position: absolute;
    width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-question__content--drag-over-inside .svc-panel__placeholder_frame,
  .svc-hovered > .svc-question__content--drag-over-inside .svc-panel__placeholder_frame {
    box-shadow: 0 0 0 1px var(--sjs-primary-backcolor, var(--primary, #293650));
    border: 1px solid var(--sjs-primary-backcolor, var(--primary, #293650));
    background: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  }
  .svc-question__content--drag-over-inside .svc-panel__placeholder_frame > .svc-question__content-actions,
  .svc-hovered > .svc-question__content--drag-over-inside .svc-panel__placeholder_frame > .svc-question__content-actions {
    opacity: 1;
  }
  
  .svc-question__content--drag-over-left:before {
    content: " ";
    position: absolute;
    left: -9px;
    background: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    top: 0;
    height: 100%;
    width: 2px;
  }
  
  .svc-question__content--drag-over-right:after {
    content: " ";
    position: absolute;
    right: -9px;
    background: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    top: 0;
    height: 100%;
    width: 2px;
  }
  
  .sd-panel .svc-question__content--drag-over-left:before {
    left: -6px;
  }
  .sd-panel .svc-question__content--drag-over-right:after {
    right: -6px;
  }
  
  .svc-question__content--drag-over-top:before {
    content: " ";
    position: absolute;
    left: 0;
    background: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    top: -9px;
    width: 100%;
    height: 2px;
  }
  
  .svc-question__content--drag-over-bottom:before {
    content: " ";
    position: absolute;
    left: 0;
    background: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    bottom: -9px;
    width: 100%;
    height: 2px;
  }
  
  .svc-question__content--panel .sd-row > div:first-child .svc-question__content--drag-over-left,
  .sd-panel__content .sd-row > div:first-child .svc-question__content--drag-over-left {
    margin-left: 8px;
    width: calc(100% - 8px);
  }
  .svc-question__content--panel .sd-row > div:last-child .svc-question__content--drag-over-right,
  .sd-panel__content .sd-row > div:last-child .svc-question__content--drag-over-right {
    margin-right: 8px;
    width: calc(100% - 8px);
  }
  .svc-question__content--panel .svc-question__content--drag-over-top:before,
  .sd-panel__content .svc-question__content--drag-over-top:before {
    top: -6px;
  }
  .svc-question__content--panel .svc-question__content--drag-over-bottom:before,
  .sd-panel__content .svc-question__content--drag-over-bottom:before {
    bottom: -6px;
  }
  
  .svc-question__content-actions .sv-action-bar {
    box-sizing: border-box;
    padding: 0;
    justify-content: center;
  }
  
  svc-question sv-action-bar,
  svc-question .sv-action-bar,
  .svc-question sv-action-bar,
  .svc-question .sv-action-bar {
    padding: 0;
  }
  
  .svc-panel__edge {
    position: absolute;
    border: 1px solid orange;
    height: 10px;
    width: 100%;
  }
  
  .svc-panel__edge--top {
    top: 0;
  }
  
  .svc-panel__edge--bottom {
    bottom: 0;
  }
  
  .svc-question__adorner--start-with-new-line[data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] {
    height: 100%;
    overflow: hidden;
    min-width: 4px;
  }
  .svc-question__adorner--start-with-new-line[data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] .svc-question__content.svc-question__content.svc-question__content {
    border: 0;
    padding: 0;
  }
  
  [data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] {
    border: none;
    height: 0;
  }
  [data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] .svc-question__drag-area {
    display: none;
  }
  [data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] .svc-question__drag-element {
    display: none;
  }
  [data-sv-drop-target-survey-element=sv-drag-drop-ghost-survey-element-name] .svc-question__content {
    background: transparent;
    box-shadow: none;
  }
  
  .svc-hovered > .svc-question__content > .svc-question__drag-area {
    visibility: visible;
  }
  
  .svc-question__content.svc-question__content--selected > .svc-question__drag-area {
    visibility: visible;
    z-index: 1;
  }
  
  .svc-question__drag-area {
    position: absolute;
    cursor: move;
    top: 0;
    left: 0;
    visibility: hidden;
    height: calc(3.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .svc-question__drag-element {
    width: 100%;
    height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    opacity: 0.5;
  }
  .svc-question__drag-element use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .sd-panel .svc-question__content--image:not(.svc-question__content--empty) {
    padding-left: 1px;
    padding-right: 1px;
  }
  .sd-panel .svc-question__content .sd-table-wrapper::before {
    left: calc(-1 * var(--sd-base-padding) + 1px);
  }
  .sd-panel .svc-question__content .sd-table-wrapper::after {
    right: calc(-1 * var(--sd-base-padding) + 1px);
  }
  .sd-panel .svc-question__content {
    border-radius: 0;
    border: 1px dashed var(--sjs-border-default, var(--border, #d6d6d6));
    box-shadow: none;
  }
  .sd-panel .svc-hovered .svc-question__content:not(.svc-question__content--dragged) {
    border: 1px solid var(--sjs-secondary-backcolor-light, var(--secondary-light, rgba(255, 152, 20, 0.25)));
    box-shadow: 0 0 0 1px var(--sjs-secondary-backcolor-light, var(--secondary-light, rgba(255, 152, 20, 0.25))) inset;
  }
  .sd-panel .svc-question__content.svc-question__content--selected:not(.svc-question__content--dragged) {
    border: 1px solid var(--sjs-secondary-backcolor, var(--secondary, #374462));
    box-shadow: 0 0 0 1px var(--sjs-secondary-backcolor, var(--secondary, #374462)) inset;
  }
  
  .sd-page__row.sd-row--multiple .svc-question__content--empty .sd-row__question,
  .sd-page__row.sd-row--multiple .svc-question__content--empty .sd-row__panel {
    height: 0;
  }
  
  .sd-panel .svc-row--ghost.svc-row--ghost .svc-question__content.svc-question__content {
    border: none;
  }
  
  .svc-widget__content {
    width: 100%;
  }
  .svc-widget__content .sd-question__content {
    pointer-events: none;
  }
  
  .svc-question__content .sd-paneldynamic__footer,
  .svc-question__content .sd-paneldynamic__separator {
    display: none;
  }
  
  .svc-panel__add-new-question-wrapper {
    container-type: inline-size;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .svc-panel__placeholder_frame-wrapper {
    container-type: inline-size;
    width: 100%;
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
  }
  
  .svc-question__content--empty-template .sd-question--paneldynamic,
  .svc-question__content--empty-template .sd-question__content,
  .svc-question__content--empty-template sv-ng-paneldynamic-question,
  .svc-question__content--empty-template .sd-paneldynamic,
  .svc-question__content--empty-template .sd-paneldynamic__panels-container,
  .svc-question__content--empty-template .sd-paneldynamic__panel-wrapper,
  .svc-question__content--empty-template .svc-question__adorner,
  .svc-question__content--empty-template .svc-question__adorner > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .svc-question__content--empty-template .sd-question--paneldynamic .svc-panel__placeholder_frame,
  .svc-question__content--empty-template .sd-question__content .svc-panel__placeholder_frame,
  .svc-question__content--empty-template sv-ng-paneldynamic-question .svc-panel__placeholder_frame,
  .svc-question__content--empty-template .sd-paneldynamic .svc-panel__placeholder_frame,
  .svc-question__content--empty-template .sd-paneldynamic__panels-container .svc-panel__placeholder_frame,
  .svc-question__content--empty-template .sd-paneldynamic__panel-wrapper .svc-panel__placeholder_frame,
  .svc-question__content--empty-template .svc-question__adorner .svc-panel__placeholder_frame,
  .svc-question__content--empty-template .svc-question__adorner > div .svc-panel__placeholder_frame {
    flex-grow: 1;
  }
  
  .sd-paneldynamic__panels-container {
    padding-bottom: 2px;
    margin-bottom: -2px;
  }
  
  .svc-panel__placeholder_frame {
    border: 1px dashed var(--sjs-border-default, var(--border, #d6d6d6));
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: calc(11 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    flex-grow: 1;
  }
  
  .svc-panel__add-new-question-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }
  
  .svc-panel__add-new-question {
    align-items: center;
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-left: 0;
    margin-right: 0;
    width: auto;
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    vertical-align: baseline;
    text-align: center;
    cursor: pointer;
    user-select: none;
    border: none;
    box-shadow: none;
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-panel__add-new-question:hover {
    outline: none;
  }
  .svc-panel__add-new-question .svc-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    margin: var(--sjs-base-unit, var(--base-unit, 8px)) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-primary-backcolor, var(--primary, #293650));
    text-wrap: nowrap;
  }
  
  .svc-panel__add-new-question-icon {
    margin: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: none;
  }
  .svc-panel__add-new-question-icon use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  @container (max-width: 496px) {
    .svc-panel__placeholder_frame {
      padding: calc(11 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    }
  }
  @container (max-width: 176px) {
    .svc-panel__placeholder {
      display: none;
    }
    .svc-panel__placeholder_frame {
      padding: calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    }
    .svc-panel__placeholder_frame .svc-panel__add-new-question {
      margin: 0;
    }
    .svc-panel__placeholder_frame .svc-panel__add-new-question-icon,
    .svc-panel__add-new-question-container .svc-panel__add-new-question-icon {
      display: block;
    }
    .svc-panel__placeholder_frame .svc-panel__question-type-selector,
    .svc-panel__add-new-question-container .svc-panel__question-type-selector {
      display: none;
    }
    .svc-panel__placeholder_frame .svc-panel__add-new-question,
    .svc-panel__add-new-question-container .svc-panel__add-new-question {
      padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    }
    .svc-panel__placeholder_frame .svc-panel__add-new-question .svc-text,
    .svc-panel__add-new-question-container .svc-panel__add-new-question .svc-text {
      display: none;
    }
  }
  .svc-panel__question-type-selector-popup {
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .svc-panel__question-type-selector {
    appearance: none;
    display: flex;
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    box-sizing: border-box;
    border: none;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    margin-right: var(--sjs-base-unit, var(--base-unit, 8px));
    outline: none;
    top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin: 0;
    position: absolute;
    right: 0;
  }
  .svc-panel__question-type-selector use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  .svc-panel__question-type-selector:hover {
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  .svc-panel__question-type-selector:focus {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  }
  
  .sv-list__container .sv-action-bar-item--secondary .sv-list__item-icon use {
    fill: var(--sjs-secondary-backcolor, var(--secondary, #374462));
  }
  
  .sd-panel .svc-row {
    margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .sd-panel .svc-row:first-of-type {
    margin-top: 0;
  }
  .sd-panel .svc-row .sd-row {
    margin-top: 0;
    margin-bottom: 0;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-question__content .sd-element__header--location-top {
    margin-top: 0;
  }
  .svc-question__content .sd-element--complex > .sd-element__header--location-top {
    padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
    padding-top: 0;
  }
  .svc-question__content .sd-element--complex > .sd-element__header--location-top:after {
    display: none;
  }
  .svc-question__content .sd-panel__content {
    gap: 0;
    padding-top: 0;
  }
  .svc-question__content .sd-table__cell--detail-panel .sd-panel__content {
    padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-question__content .sd-paneldynamic__panel-wrapper {
    padding: 0;
  }
  .svc-question__content .sd-table__cell--actions .sv-action-bar-item:disabled {
    background: var(--sjs-general-backcolor, var(--background, #fff));
    opacity: 1;
  }
  .svc-question__content .sd-table__cell--actions .sv-action-bar-item:disabled use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  .svc-question__content .sd-table__row-disabled > .sd-table__cell {
    opacity: 1;
  }
  
  .svc-question__content .svc-carry-forward-panel {
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-question__content .sd-question__content {
    min-width: min(8 * (var(--sjs-base-unit, var(--base-unit, 8px))), 100%);
    margin-left: calc(-5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-right: calc(-5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-left: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-right: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    overflow-x: auto;
  }
  .svc-question__content .sd-composite .sd-question__content {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  
  .svc-question__content--paneldynamic .sd-question__content {
    padding-bottom: 2px;
    margin-bottom: -2px;
  }
  
  .svc-question__content--ranking .svc-carry-forward-panel {
    margin-top: 0;
  }
  
  .svc-required-action .sv-svg-icon use {
    fill: var(--sjs-secondary-backcolor, var(--secondary, #374462));
  }
  
  .svc-required-action--active:not(.sv-action-bar-item--pressed):enabled:hover,
  .svc-required-action--active:not(.sv-action-bar-item--pressed):enabled:focus,
  .svc-required-action--active {
    background-color: var(--sjs-secondary-backcolor-semi-light, transparent)};
  .svc-question__dropdown-choices {
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-question__dropdown-choice--collapsed:nth-last-child(1) {
    opacity: 0.25;
  }
  
  .svc-question__dropdown-choice--collapsed:nth-last-child(2) {
    opacity: 0.5;
  }
  
  .svc-question__dropdown-choice--collapsed:nth-last-child(3) {
    opacity: 0.75;
  }
  
  .svc-question__content > .svc-question__dropdown-choices--wrapper {
    width: calc(100% + 8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    overflow-x: auto;
    margin: 0 calc(-4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-question__content > .svc-question__dropdown-choices--wrapper > div {
    width: calc(100% - 8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: inline-block;
    min-width: calc(15 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin: 0 calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-question__content > .svc-question__dropdown-choices--wrapper .svc-action-button {
    margin-left: calc(6.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
    cursor: pointer;
  }
  .svc-image-question-controls {
    position: absolute;
    top: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    right: var(--sjs-base-unit, var(--base-unit, 8px));
    display: none;
  }
  
  .svc-question__content--selected .svc-image-question-controls {
    display: flex;
  }
  
  .svc-question__content--image:not(.svc-question__content--loading):not(.svc-question__content--empty) {
    padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
  }
  .svc-question__content--image:not(.svc-question__content--loading):not(.svc-question__content--empty) .sd-image__image {
    border-radius: 0;
  }
  
  .svc-question__content--image.svc-question__content--empty .sd-file,
  .svc-question__content--image.svc-question__content--empty sv-ng-file-question {
    width: 100%;
  }
  
  .svc-question__content--image.svc-question__content--empty .sd-question--image {
    display: none;
  }
  
  .svc-question__content--image .sd-file__choose-btn--text .sv-svg-icon {
    display: none;
  }
  
  @container (max-width: 176px) {
    .svc-question__content--image .sd-file__choose-btn--text .sv-svg-icon {
      display: block;
    }
  }
  .svc-question__content--loading .sd-image {
    display: none;
  }
  
  .svc-image-question__loading-placeholder {
    position: relative;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    width: 100%;
    height: calc(27.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border: 1px dashed var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  }
  
  .svc-image-question__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .svc-rating-question-controls {
    position: absolute;
  }
  
  .svc-question__content--in-popup .svc-rating-question-controls.svc-item-value-controls {
    display: flex;
  }
  
  .svc-question__content--selected .svc-rating-question-controls.svc-item-value-controls {
    display: flex;
  }
  
  .svc-rating-question-controls.svc-rating-question-controls--labels-top {
    margin-top: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-question__content .sd-rating {
    margin-inline-start: calc(9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    max-width: calc(100% - 9 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  .svc-question__content .sd-rating.sd-rating--wrappable fieldset {
    min-width: 0;
  }
  .svc-question__content .sd-rating .sd-rating__item-text {
    max-width: 100%;
  }
  .svc-question__content .sd-rating .sd-rating__item-text .sv-string-editor:focus-within {
    white-space: nowrap;
  }
  .svc-question__content .sd-rating .svc-string-editor__content .sv-string-editor {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .svc-question__content .sd-rating .svc-string-editor__content .sv-string-editor:focus-within {
    text-overflow: initial;
  }
  .svc-question__content .sd-rating.sd-rating--labels-top fieldset .sd-rating__min-text {
    top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-question__content .sd-rating.sd-rating--labels-top fieldset .sd-rating__max-text {
    top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-question__content .sd-rating.sd-rating--labels-bottom fieldset {
    padding-bottom: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-question__content .sd-rating.sd-rating--labels-bottom fieldset .sd-rating__min-text {
    bottom: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-question__content .sd-rating.sd-rating--labels-bottom fieldset .sd-rating__max-text {
    bottom: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-question__content .sd-rating.sd-rating--labels-diagonal fieldset {
    padding-bottom: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-question__content .sd-rating.sd-rating--labels-diagonal fieldset .sd-rating__min-text {
    top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-question__content .sd-rating.sd-rating--labels-diagonal fieldset .sd-rating__max-text {
    bottom: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-question__content .sd-question--table .sd-rating {
    margin-inline-start: 0;
    width: fit-content;
    margin: auto;
    max-width: fit-content;
  }
  .svc-question__content .svc-rating-question-controls {
    display: flex;
    width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    min-width: auto;
    justify-content: flex-start;
  }
  .svc-question__content .sd-rating__item--fixed-size:focus-within {
    width: unset;
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-creator--mobile .svc-rating-question-content {
    position: relative;
  }
  .svc-creator--mobile .svc-rating-question-controls {
    position: absolute;
    top: 0;
  }
  .svc-creator--mobile .svc-question__content .sd-rating {
    margin-inline-start: 0;
    width: 100%;
    max-width: 100%;
  }
  .svc-creator--mobile .svc-question__content legend + .sd-rating__item,
  .svc-creator--mobile .svc-question__content legend + sv-ng-rating-item + .sd-rating__item,
  .svc-creator--mobile .svc-question__content legend + .sd-rating__item-smiley,
  .svc-creator--mobile .svc-question__content legend + sv-ng-rating-item-smiley + .sd-rating__item-smiley {
    margin-inline-start: calc(9 * var(--sjs-base-unit, var(--base-unit, 8px)) + 2px);
  }
  .svc-creator--mobile .svc-question__content legend + .sd-rating__item-text,
  .svc-creator--mobile .svc-question__content legend + .sd-rating__item-star,
  .svc-creator--mobile .svc-question__content legend + sv-ng-rating-item-star + .sd-rating__item-star {
    margin-inline-start: calc(9 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  .svc-item-value-wrapper {
    position: relative;
    display: flex;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
    align-items: center;
    margin-left: calc(-5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-question__dropdown-choice .svc-item-value-wrapper,
  .sd-selectbase .svc-item-value-wrapper {
    align-items: flex-start;
  }
  .svc-question__dropdown-choice .svc-item-value__item,
  .sd-selectbase .svc-item-value__item {
    padding-right: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-question__content .sd-selectbase__column {
    margin-left: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    min-width: initial;
  }
  .svc-question__content .sd-selectbase--mobile .sd-selectbase__column,
  .svc-question__content .sd-selectbase__column:first-of-type,
  .svc-question__content .sd-imagepicker__column {
    margin-left: 0;
  }
  .svc-question__content .sd-selectbase__column:not(:last-child) {
    padding-right: 0;
  }
  
  .svc-item-value--dragging {
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-left: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .svc-item-value-controls {
    display: flex;
    margin: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
    height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    flex-shrink: 0;
    min-width: 72px;
    justify-content: flex-end;
  }
  
  .svc-item-value-controls__button {
    border-radius: 50%;
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    cursor: pointer;
    display: inline-block;
    outline: none;
    height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: content-box;
  }
  .svc-item-value-controls__button .sv-svg-icon {
    display: block;
  }
  
  .svc-item-value-controls__button > span {
    display: block;
  }
  
  .svc-item-value-controls__drag-icon {
    display: block;
  }
  
  .svc-item-value-wrapper:hover:not(.svc-item-value--ghost) .svc-item-value-controls__drag-icon,
  .svc-item-value-controls__drag:hover:not(.svc-item-value--ghost) .svc-item-value-controls__drag-icon {
    visibility: visible;
  }
  
  .svc-item-value-controls__drag {
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px));
    cursor: move;
    opacity: 0.25;
  }
  .svc-item-value-controls__drag .svc-item-value-controls__drag-icon {
    visibility: hidden;
  }
  .svc-item-value-controls__drag use {
    fill: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  
  .svc-item-value-controls__button--disabled {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    opacity: 0.25;
    cursor: default;
  }
  
  .svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled) use {
    fill: var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .svc-item-value-controls__add:not(.svc-item-value-controls__button--disabled) use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-item-value-controls__add:not(.svc-item-value-controls__button--disabled):hover, .svc-item-value-controls__add:not(.svc-item-value-controls__button--disabled):focus {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  }
  
  .svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled):hover, .svc-item-value-controls__remove:not(.svc-item-value-controls__button--disabled):focus {
    background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
  }
  
  .sv-string-editor {
    cursor: text;
  }
  
  .svc-item-value--new .svc-item-value__item .sd-item__control-label,
  .svc-item-value--new .svc-item-value__item .sv-ranking-item__text {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  .svc-item-value--new .svc-item-value__item .sd-item__decorator {
    opacity: 0.35;
  }
  .svc-item-value--new .sv-ranking-item__index {
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  .svc-item-value--new .sv-ranking-item__index svg {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .sv-ranking .svc-item-value-wrapper {
    align-items: center;
  }
  .sv-ranking .svc-item-value__item {
    padding-right: 0;
    flex-grow: 1;
  }
  
  .svc-question__content .svc-item-value__item .sv-ranking-item__text {
    flex-grow: 1;
    width: 0;
    max-width: fit-content;
  }
  
  .svc-item-value__item .sv-ranking-item__content {
    padding-left: 0;
  }
  .svc-item-value__item .sv-ranking-item__icon-container {
    display: none;
  }
  .svc-item-value__item .sv-ranking-item__text {
    overflow: visible;
  }
  .svc-item-value__item .svc-string-editor {
    width: 100%;
    display: inline-block;
  }
  .svc-item-value__item .sv-string-editor {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .svc-item-value--ghost .svc-item-value__ghost {
    display: block;
  }
  .svc-item-value--ghost .svc-item-value-controls {
    visibility: hidden;
  }
  
  .svc-item-value--movedown {
    transform: translate(0, 0);
    animation: svdragdropmovedown 0.1s;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes svdragdropmovedown {
    0% {
      transform: translate(0, -50px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .svc-item-value--moveup {
    transform: translate(0, 0);
    animation: svdragdropmoveup 0.1s;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes svdragdropmoveup {
    0% {
      transform: translate(0, 50px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .svc-item-value__ghost {
    display: none;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: calc(25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: 40px;
    z-index: 11;
    position: absolute;
    left: 35px;
  }
  
  .svc-creator--mobile .svc-tab-designer .svc-question__content--ranking [data-sv-drop-target-item-value] .svc-item-value-controls,
  .svc-creator--mobile .svc-tab-designer .sd-selectbase [data-sv-drop-target-item-value] .svc-item-value-controls,
  .svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices [data-sv-drop-target-item-value] .svc-item-value-controls {
    min-width: initial;
  }
  .svc-creator--mobile .svc-tab-designer .svc-question__content--ranking:not([data-sv-drop-target-item-value]) .svc-item-value-controls,
  .svc-creator--mobile .svc-tab-designer .sd-selectbase:not([data-sv-drop-target-item-value]) .svc-item-value-controls,
  .svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices:not([data-sv-drop-target-item-value]) .svc-item-value-controls {
    min-width: 64px;
  }
  .svc-creator--mobile .svc-tab-designer .svc-question__content--ranking .svc-item-value-controls__drag,
  .svc-creator--mobile .svc-tab-designer .sd-selectbase .svc-item-value-controls__drag,
  .svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-controls__drag {
    padding-right: 0;
  }
  .svc-creator--mobile .svc-tab-designer .svc-question__content--ranking .svc-item-value-controls__drag-icon,
  .svc-creator--mobile .svc-tab-designer .sd-selectbase .svc-item-value-controls__drag-icon,
  .svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-controls__drag-icon {
    visibility: visible;
  }
  .svc-creator--mobile .svc-tab-designer .svc-question__content--ranking .svc-item-value-wrapper,
  .svc-creator--mobile .svc-tab-designer .sd-selectbase .svc-item-value-wrapper,
  .svc-creator--mobile .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-wrapper {
    margin-left: calc(-3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__content--ranking .svc-item-value-controls__drag-icon,
  .svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .sd-selectbase .svc-item-value-controls__drag-icon,
  .svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-controls__drag-icon {
    visibility: visible;
  }
  .svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__content--ranking .svc-item-value-wrapper,
  .svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .sd-selectbase .svc-item-value-wrapper,
  .svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-wrapper {
    margin-left: calc(-6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__content--ranking .svc-item-value-controls__drag,
  .svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .sd-selectbase .svc-item-value-controls__drag,
  .svc-creator--touch:not(.svc-creator--mobile) .svc-tab-designer .svc-question__dropdown-choices .svc-item-value-controls__drag {
    padding-left: 0;
    padding-right: 0;
  }
  .svc-image-item-value-wrapper {
    display: inline-block;
    position: relative;
  }
  
  .svc-image-item-value--new {
    display: none;
    height: max-content;
  }
  .svc-image-item-value--new .sd-imagepicker__image {
    box-sizing: border-box;
    border: 1px dashed var(--sjs-border-default, var(--border, #d6d6d6));
  }
  
  .svc-image-item-value--file-dragging .sd-imagepicker__image {
    border: 2px solid var(--sjs-primary-backcolor, var(--primary, #293650));
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  }
  .svc-image-item-value--file-dragging .svc-image-item-value__item {
    position: relative;
    z-index: 2;
  }
  
  .svc-question__content--selected .svc-image-item-value--new {
    display: inline-block;
  }
  
  .svc-image-item-value-controls {
    position: absolute;
    top: var(--sjs-base-unit, var(--base-unit, 8px));
    right: var(--sjs-base-unit, var(--base-unit, 8px));
    z-index: 2;
  }
  
  .svc-image-item-value--new .svc-image-item-value-controls {
    top: calc(50% - 3 * var(--sjs-base-unit, var(--base-unit, 8px)));
    left: calc(50% - 3 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  
  .svc-image-item-value--new .svc-image-item-value-controls__button {
    background-color: transparent;
  }
  
  .svc-image-item-value-controls__add {
    background-color: transparent;
  }
  .svc-image-item-value-controls__add use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  .svc-image-item-value-controls__add:hover, .svc-image-item-value-controls__add:focus {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  }
  
  .svc-choose-file-input {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  
  .svc-image-item-value__item .sd-imagepicker__item.sd-imagepicker__item {
    width: 100%;
  }
  
  .svc-image-item-value-controls__drag-area-indicator {
    display: none;
    cursor: move;
    position: absolute;
    top: var(--sjs-base-unit, var(--base-unit, 8px));
    left: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-question__content--selected .svc-image-item-value-wrapper:hover .svc-image-item-value-controls__drag-area-indicator {
    display: block;
  }
  
  .svc-image-item-value-wrapper--ghost .svc-image-item-value-wrapper__ghost {
    display: block;
  }
  .svc-image-item-value-wrapper--ghost .svc-image-item-value-wrapper__content {
    display: none;
  }
  
  .svc-image-item-value-wrapper__ghost {
    display: none;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .svc-image-item-value--single {
    width: 100%;
  }
  .svc-image-item-value--single .sd-imagepicker__image {
    height: calc(30 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-image-item-value__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .svc-matrix-cell {
    position: relative;
  }
  
  .svc-question__adorner .sd-table .sd-table__cell--column-title {
    padding: 0 var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-question__adorner .sd-table .sd-table__cell--column-title .svc-matrix-cell {
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-matrix-cell--selected {
    position: absolute;
    border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-secondary-backcolor, var(--secondary, #374462));
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    top: calc(0 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    left: calc(0 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    right: calc(0 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    bottom: calc(0 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: none;
  }
  .svc-matrix-cell--selected.svc-visible {
    display: block;
  }
  
  .svc-hovered > .svc-matrix-cell--selected:not(.svc-visible) {
    border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-secondary-backcolor-light, var(--secondary-light, rgba(255, 152, 20, 0.25)));
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: block;
  }
  
  .svc-matrix-cell__question-controls {
    display: none;
    position: absolute;
    top: calc(50% - 3 * var(--sjs-base-unit, var(--base-unit, 8px)));
    left: calc(50% - 3 * var(--sjs-base-unit, var(--base-unit, 8px)));
    z-index: 2;
  }
  
  .svc-matrix-cell__question-controls-button {
    display: block;
    width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    box-sizing: border-box;
    border-radius: 50%;
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    cursor: pointer;
  }
  .svc-matrix-cell__question-controls-button use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .svc-matrix-cell:hover .svc-matrix-cell__question-controls {
    display: block;
  }
  
  .svc-question__content--in-popup {
    width: calc(100% - 25 * var(--sjs-base-unit, var(--base-unit, 8px)));
    min-width: calc(70 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-matrix-cell__popup .sv-popup__scrolling-content {
    margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
    padding-top: 0;
  }
  .svc-matrix-cell__popup .svc-question__content {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .svc-question__content .sd-table__cell--detail-panel {
    padding-left: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svd-test-results {
    font-family: var(--sjs-font-family, var(--font-family));
    border-radius: 4px;
    box-shadow: inset 0px 1px 0px 0px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
    padding: calc(12 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  .svd-test-results .svd-test-results__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    border-bottom: 2px solid var(--sjs-border-light, var(--border-light, #eaeaea));
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
  }
  .svd-test-results .svd-test-results__header .svd-test-results__header-text {
    margin: 0;
  }
  .svd-test-results .svd-test-results__header .svd-test-results__header-types {
    display: flex;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svd-test-results .svd-test-results__text {
    display: flex;
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: calc(100% - 6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    overflow: auto;
    user-select: text;
    white-space: pre-wrap;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
  }
  .svd-test-results .svd-test-results__table {
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    width: 100%;
    box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
  }
  .svd-test-results .svd-test-results__table table {
    width: 100%;
    border-collapse: collapse;
  }
  .svd-test-results .svd-test-results__table table .svd-test-results__marker {
    position: absolute;
    top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svd-test-results .svd-test-results__table table .svd-test-results__marker .sv-svg-icon {
    transition: transform 0.2s ease-in-out;
  }
  .svd-test-results .svd-test-results__table table .svd-test-results__marker .sv-svg-icon use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  .svd-test-results .svd-test-results__table table .svd-test-results__marker.svd-test-results__marker--expanded .sv-svg-icon {
    transform-origin: center;
    transform: rotate(90deg);
  }
  .svd-test-results .svd-test-results__table table th {
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px));
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    text-align: left;
    font-weight: 600;
    font-size: 12px;
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  .svd-test-results .svd-test-results__table table th:nth-child(1) {
    padding-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svd-test-results .svd-test-results__table table td {
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px));
    max-width: calc(50 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    overflow: auto;
    border-top: 2px solid var(--sjs-border-light, var(--border-light, #eaeaea));
    user-select: text;
  }
  .svd-test-results .svd-test-results__table table td:nth-child(1) {
    position: relative;
    width: 50%;
    font-weight: 600;
  }
  .svd-test-results .svd-test-results__table table td:nth-child(2) {
    width: 50%;
    padding-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svd-test-results .svd-test-results__table table .survey-result-value {
    word-break: break-all;
  }
  .svd-test-results .svd-test-results__table table tr {
    vertical-align: top;
  }
  .svd-test-results .svd-test-results__table table tr .svd-test-results__node-value {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  .svc-designer-header .sd-container-modern__title {
    gap: 0;
    column-gap: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-logo-image {
    position: relative;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    padding: 0;
    cursor: pointer;
    max-width: 50%;
  }
  .svc-logo-image .sd-logo__image {
    max-width: 100%;
  }
  .svc-logo-image .svc-image-item-value-controls {
    display: block;
    top: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    right: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-logo-image__loading {
    width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    box-sizing: border-box;
    margin-right: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-logo-image-placeholder {
    font-size: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    opacity: 0.25;
    white-space: nowrap;
    word-break: keep-all;
    padding: 0;
    width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border-radius: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-logo-image-placeholder:hover {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
    opacity: unset;
  }
  .svc-logo-image-placeholder:hover use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  .svc-logo-image-placeholder svg {
    width: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-logo-image-placeholder use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .svc-logo-image-container {
    position: relative;
  }
  .svc-logo-image-container .sd-logo {
    margin: 0;
  }
  
  .svc-logo-image-controls {
    opacity: 0;
    width: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .svc-logo-image-container {
    cursor: default;
  }
  
  .svc-logo-image-container:not(.svc-logo-image-container--editable) .svc-logo-image-controls {
    display: none;
  }
  
  .svc-logo-image-container--editable:focus-within .svc-logo-image-controls,
  .svc-logo-image-container--editable:hover .svc-logo-image-controls {
    opacity: 1;
  }
  .svc-question-link__set-button {
    font-weight: 600;
    cursor: pointer;
  }
  
  .svc-link-value-button {
    margin-left: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-question-link__clear-button {
    color: var(--sjs-special-red, var(--red, #e60a3e));
    font-weight: 600;
    cursor: pointer;
  }
  
  .svc-question-link__clear-button:focus,
  .svc-question-link__clear-button:hover {
    background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
  }
  .svc-property-panel__item {
    margin: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
    width: 100%;
  }
  .svc-property-panel__group {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border: none;
    outline: none;
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    cursor: pointer;
    text-align: left;
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: inset 0px -1px 0px var(--sjs-border-default, var(--border, #d6d6d6));
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-property-panel__group:disabled,
  .svc-property-panel__group:disabled:hover {
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 0.25;
    cursor: default;
  }
  
  .svc-property-panel__group:hover {
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    font-weight: 400;
  }
  
  .svc-property-panel__group--active,
  .svc-property-panel__group--active:hover {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    font-weight: 600;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
  }
  .svc-tabbed-menu-item {
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    cursor: pointer;
  }
  .svc-tabbed-menu-item:hover, .svc-tabbed-menu-item:focus {
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    box-shadow: inset 0px -1px 0px var(--sjs-border-default, var(--border, #d6d6d6));
    outline: none;
  }
  
  .svc-tabbed-menu-item--selected {
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: inset 0px -2px 0px var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  .svc-tabbed-menu-item--selected:hover, .svc-tabbed-menu-item--selected:focus {
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: inset 0px -2px 0px var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-tabbed-menu-item--disabled {
    cursor: default;
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 0.25;
  }
  .svc-tabbed-menu-item--disabled:hover {
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
  }
  
  .svc-tabbed-menu-item--hidden {
    visibility: hidden;
  }
  
  .svc-tabbed-menu-item .svc-tabbed-menu-item__text {
    white-space: nowrap;
    line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-tabbed-menu-item-container .sv-dots__item {
    width: auto;
  }
  .svc-tabbed-menu {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-tabbed-menu-wrapper {
    flex: 1 1 auto;
    overflow: hidden;
  }
  .svc-toolbox__item {
    display: flex;
    position: relative;
    align-items: center;
    z-index: 20;
    outline: none;
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-toolbox__item sv-svg-icon,
  .svc-toolbox__item .sv-svg-icon {
    display: block;
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-toolbox__item-container {
    outline: none;
  }
  .svc-toolbox__item-container .sv-svg-icon use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .svc-toolbox__item-banner {
    opacity: 0;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    margin-right: 0;
    margin-left: calc(-1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-toolbox__item-banner .svc-toolbox__item-icon {
    position: absolute;
    display: block;
    left: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-toolbox__item-banner .svc-toolbox__item-title {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  .svc-toolbox__item-banner .sv-svg-icon use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-creator__toolbox--right .svc-toolbox__item:hover .svc-toolbox__item-banner,
  .svc-creator__toolbox--right .svc-toolbox__item:focus .svc-toolbox__item-banner,
  [dir=rtl] .svc-toolbox__item:hover .svc-toolbox__item-banner,
  [dir=rtl] .svc-toolbox__item:focus .svc-toolbox__item-banner,
  [style*="direction:rtl"] .svc-toolbox__item:hover .svc-toolbox__item-banner,
  [style*="direction:rtl"] .svc-toolbox__item:focus .svc-toolbox__item-banner,
  [style*="direction: rtl"] .svc-toolbox__item:hover .svc-toolbox__item-banner,
  [style*="direction: rtl"] .svc-toolbox__item:focus .svc-toolbox__item-banner {
    flex-direction: row-reverse;
    padding-right: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-left: 0;
    margin-right: 0;
    transform: translateX(calc(1.5 * var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator__toolbox--right .svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
  .svc-creator__toolbox--right .svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title,
  [dir=rtl] .svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
  [dir=rtl] .svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title,
  [style*="direction:rtl"] .svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
  [style*="direction:rtl"] .svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title,
  [style*="direction: rtl"] .svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
  [style*="direction: rtl"] .svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title {
    padding-left: var(--sjs-base-unit, var(--base-unit, 8px));
    padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-creator__toolbox--right .svc-toolbox__item,
  [dir=rtl] .svc-toolbox__item,
  [style*="direction:rtl"] .svc-toolbox__item,
  [style*="direction: rtl"] .svc-toolbox__item {
    flex-direction: row-reverse;
  }
  .svc-creator__toolbox--right .svc-toolbox__tool.sv-dots,
  [dir=rtl] .svc-toolbox__tool.sv-dots,
  [style*="direction:rtl"] .svc-toolbox__tool.sv-dots,
  [style*="direction: rtl"] .svc-toolbox__tool.sv-dots {
    place-self: flex-end;
  }
  .svc-creator__toolbox--right .svc-toolbox__tool,
  [dir=rtl] .svc-toolbox__tool,
  [style*="direction:rtl"] .svc-toolbox__tool,
  [style*="direction: rtl"] .svc-toolbox__tool {
    justify-content: flex-end;
  }
  .svc-creator__toolbox--right .svc-toolbox__category,
  [dir=rtl] .svc-toolbox__category,
  [style*="direction:rtl"] .svc-toolbox__category,
  [style*="direction: rtl"] .svc-toolbox__category {
    align-items: flex-end;
  }
  .svc-creator__toolbox--right .svc-toolbox__item-banner .svc-toolbox__item-icon,
  [dir=rtl] .svc-toolbox__item-banner .svc-toolbox__item-icon,
  [style*="direction:rtl"] .svc-toolbox__item-banner .svc-toolbox__item-icon,
  [style*="direction: rtl"] .svc-toolbox__item-banner .svc-toolbox__item-icon {
    left: auto;
    right: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-toolbox__item-title {
    --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.75 * var(--small-bold-font-size));
    line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
    display: block;
    padding-left: var(--sjs-base-unit, var(--base-unit, 8px));
    padding-right: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    white-space: nowrap;
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .svc-toolbox__item:hover .svc-toolbox__item-banner,
  .svc-toolbox__item:focus .svc-toolbox__item-banner {
    max-width: calc(100 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-left: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    opacity: 1;
  }
  .svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
  .svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title {
    padding-left: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):focus,
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover {
    overflow: hidden;
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
    transition: 0.1s ease-in-out;
  }
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):focus .svc-toolbox__item-title,
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover .svc-toolbox__item-title {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):focus .sv-svg-icon use,
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover .sv-svg-icon use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .svc-toolbox__tool .sv-dots__item:hover:enabled,
  .svc-toolbox__tool .sv-dots__item:focus:enabled,
  .svc-toolbox__tool .sv-dots__item.sv-action-bar-item--pressed {
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-toolbox__tool .sv-dots__item:hover:enabled .sv-svg-icon use,
  .svc-toolbox__tool .sv-dots__item:focus:enabled .sv-svg-icon use,
  .svc-toolbox__tool .sv-dots__item.sv-action-bar-item--pressed .sv-svg-icon use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  [dir=rtl] .svc-toolbox-popup,
  [style*="direction:rtl"] .svc-toolbox-popup,
  [style*="direction: rtl"] .svc-toolbox-popup {
    direction: rtl;
  }
  
  .svc-toolbox-popup .sv-list__item:first-child .sv-list__item-separator {
    display: none;
  }
  .svc-toolbox-popup .sv-list__item .sv-list__item-body {
    padding-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-bottom: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-inline-start: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding-inline-end: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-toolbox .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots),
  .svc-toolbox .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots):hover,
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots),
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots):hover {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 0.5;
  }
  .svc-toolbox .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots) .sv-svg-icon use,
  .svc-toolbox .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots):hover .sv-svg-icon use,
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots) .sv-svg-icon use,
  .svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots):hover .sv-svg-icon use {
    fill: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 0.5;
  }
  svc-toolbox {
    display: flex;
    flex-direction: column;
    position: absolute;
  }
  
  .svc-toolbox {
    height: 100%;
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))) - 12px);
    box-sizing: border-box;
    overflow-y: auto;
    direction: rtl;
    scrollbar-gutter: stable both-edges;
  }
  
  .svc-side-bar .svc-toolbox {
    direction: ltr;
  }
  
  _::-webkit-full-page-media,
  _:future,
  :root .svc-toolbox {
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-toolbox--compact {
    width: 100vw;
    margin-right: calc(-100vw + 10.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    overflow-y: scroll;
    scrollbar-gutter: auto;
  }
  
  .svc-creator__toolbox--right .svc-toolbox,
  [dir=rtl] .svc-toolbox,
  [style*="direction:rtl"] .svc-toolbox,
  [style*="direction: rtl"] .svc-toolbox {
    direction: ltr;
  }
  .svc-creator__toolbox--right .svc-toolbox--compact,
  [dir=rtl] .svc-toolbox--compact,
  [style*="direction:rtl"] .svc-toolbox--compact,
  [style*="direction: rtl"] .svc-toolbox--compact {
    margin-right: 0;
    margin-left: calc(-100vw + 10.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    direction: ltr;
  }
  .svc-creator__toolbox--right .svc-toolbox__tool .sv-action__content,
  [dir=rtl] .svc-toolbox__tool .sv-action__content,
  [style*="direction:rtl"] .svc-toolbox__tool .sv-action__content,
  [style*="direction: rtl"] .svc-toolbox__tool .sv-action__content {
    align-items: flex-end;
  }
  
  .svc-toolbox__container {
    direction: ltr;
  }
  
  .svc-toolbox__category {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .svc-toolbox__tool:not(.sv-dots) {
    display: flex;
    width: auto;
  }
  
  .svc-toolbox__tool .sv-dots__item {
    width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-toolbox__category-separator {
    height: 1px;
    width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: var(--sjs-border-default, var(--border, #d6d6d6));
    margin: var(--sjs-base-unit, var(--base-unit, 8px)) 0 calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-toolbox__category-header {
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    border-bottom: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    box-sizing: border-box;
    width: 100%;
    min-width: calc(20 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    position: relative;
  }
  
  .svc-toolbox__category-header--collapsed {
    cursor: pointer;
  }
  
  .svc-toolbox__category-title {
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-weight: bold;
    line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    vertical-align: middle;
    display: block;
  }
  
  .svc-toolbox__category-header__controls {
    position: absolute;
    right: var(--sjs-base-unit, var(--base-unit, 8px));
    top: var(--sjs-base-unit, var(--base-unit, 8px));
    display: none;
  }
  
  .svc-toolbox__category-header__button {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .svc-toolbox__category-header:hover .svc-toolbox__category-header__controls {
    display: block;
  }
  
  .svc-toolbox__tool {
    cursor: default;
  }
  .svc-toolbox__tool .sv-action__content {
    display: flex;
    padding-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    flex-direction: column;
    align-items: flex-start;
  }
  
  .svc-toolbox__category--collapsed .svc-toolbox__tool {
    height: 0;
    visibility: hidden;
  }
  .svc-side-bar__container {
    display: block;
    position: relative;
    font-family: var(--sjs-font-family, var(--font-family));
    min-width: 370px;
    width: 450px;
    height: 100%;
  }
  
  .svc-side-bar__container .svc-resizer {
    width: 3px;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    background-color: transparent;
    cursor: e-resize;
  }
  
  .svc-resizer-west {
    left: 0;
  }
  
  .svc-resizer-east {
    right: 0;
  }
  
  .svc-side-bar__container-header {
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: inset 0px -2px 0px var(--sjs-primary-backcolor, var(--primary, #293650));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: flex;
    height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-side-bar__container-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: calc(100% - 8 * var(--sjs-base-unit, var(--base-unit, 8px)));
    overflow-y: auto;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
  }
  
  .svc-side-bar__container-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    box-shadow: inset 0px -1px 0px var(--sjs-border-default, var(--border, #d6d6d6));
  }
  
  .svc-side-bar__container-title {
    display: flex;
    line-height: calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-end;
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-side-bar__container-actions {
    width: 100%;
    box-sizing: border-box;
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .svc-side-bar__container-actions .sv-action-bar {
    justify-content: flex-end;
    padding: 0;
  }
  
  .svc-flex-column.svc-side-bar__wrapper {
    width: auto;
    border-left: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    height: 100%;
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .svc-side-bar {
    height: 100%;
  }
  
  .svc-flyout-side-bar {
    width: 0;
    overflow: visible;
  }
  .svc-flyout-side-bar .svc-side-bar__shadow {
    background-color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    opacity: 0.75;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 900;
  }
  .svc-flyout-side-bar .svc-side-bar__wrapper {
    border-left: none;
    z-index: 1000;
    position: absolute;
    right: 0;
    display: inline-block;
    height: 100%;
    max-width: 100%;
  }
  
  .sv-action--object-selector {
    max-width: 70%;
  }
  .sv-action--object-selector .sv-action-bar-item {
    flex-shrink: 1;
  }
  .sv-action--object-selector .sv-action-bar-item__title {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .svc-full-container.svc-creator__side-bar--left {
    flex-direction: row-reverse;
  }
  .svc-full-container.svc-creator__side-bar--left .svc-side-bar__wrapper {
    left: 0;
    right: unset;
  }
  .svc-full-container.svc-creator__side-bar--left .svc-side-bar__container-header .sv-action-bar {
    flex-direction: row-reverse;
  }
  .svc-full-container.svc-creator__side-bar--left .sv-action--object-selector {
    justify-content: flex-start;
    flex: 1;
    display: flex;
  }
  .svc-full-container.svc-creator__side-bar--left .svd-grid-hide .sv-svg-icon {
    transform: rotate(180deg);
  }
  
  .svd-grid-hide {
    margin-inline-end: auto;
  }
  
  .sv-mobile-side-bar .svc-flyout-side-bar .svc-side-bar__wrapper {
    width: 100%;
  }
  .sv-mobile-side-bar .svc-side-bar__container {
    width: 100%;
  }
  
  .svc-side-bar .svc-toolbox {
    width: 100%;
  }
  .spg-action-bar {
    display: flex;
    box-sizing: content-box;
    padding-right: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    position: relative;
    align-items: center;
    margin-left: auto;
    overflow: hidden;
    white-space: nowrap;
  }
  .spg-action-bar .sv-action:not(.sv-action--hidden):not(:last-of-type) > .sv-action__content {
    padding-right: 0;
  }
  
  .spg-action-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    box-sizing: border-box;
    border: none;
    color: var(--sjs-primary-backcolor, var(--primary, #293650));
    font-weight: 600;
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: transparent;
    cursor: pointer;
  }
  .spg-action-button:hover, .spg-action-button:focus {
    opacity: 1;
    outline: none;
    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  }
  .spg-action-button:hover use, .spg-action-button:focus use {
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .spg-action-button__icon {
    display: block;
  }
  .spg-action-button__icon use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .spg-action-button--danger {
    color: var(--sjs-special-red, var(--red, #e60a3e));
  }
  .spg-action-button--danger:hover, .spg-action-button--danger:focus {
    background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
  }
  .spg-action-button--danger:hover use, .spg-action-button--danger:focus use {
    fill: var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  button.spg-action-button--large {
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-action-button:disabled {
    opacity: 0.25;
    pointer-events: none;
    cursor: default;
  }
  
  .spg-action-button--muted {
    opacity: 0.5;
  }
  
  .spg-action-button:active {
    opacity: 0.5;
  }
  
  .spg-action-button--text {
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }
  
  .spg-question__header {
    width: 100%;
  }
  
  .spg-question__title {
    width: 100%;
    margin: 0;
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    font-weight: normal;
    box-sizing: border-box;
  }
  
  .spg-question__content {
    width: 100%;
    color: var(--sjs-primary-backcolor, var(--primary, #293650));
    cursor: pointer;
  }
  
  .spg-text__content,
  .spg-comment__content {
    position: relative;
  }
  
  .spg-question__content:focus-within .spg-remaining-character-counter {
    display: flex;
  }
  
  .spg-remaining-character-counter {
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    font-family: var(--sjs-font-family, var(--font-family));
    line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    position: absolute;
    inset-inline-end: calc(0.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    inset-block-end: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-question__description {
    line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
    padding-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    white-space: normal;
  }
  
  .spg-row-narrow__question {
    margin-top: calc(-2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-row--multiple > div > .spg-row-narrow__question {
    margin-top: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-question--location--left {
    flex-direction: row;
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: inset 0 0 0 1px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
    line-height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    vertical-align: middle;
    align-items: stretch;
  }
  .spg-question--location--left:focus-within {
    box-shadow: inset 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .spg-question__header--location--left {
    width: auto;
    box-sizing: border-box;
    max-width: 50%;
    flex: 1;
    display: flex;
    align-items: center;
  }
  .spg-question__header--location--left .spg-question__title {
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border-right: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    display: inline-block;
  }
  
  .spg-question__content--left {
    flex: 2;
  }
  .spg-question__content--left .spg-input.spg-input.spg-input {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
  .spg-question__content--left .spg-input.spg-input.spg-input:focus, .spg-question__content--left .spg-input.spg-input.spg-input:focus-within {
    box-shadow: none;
  }
  
  .spg-row--multiple {
    display: flex;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
    flex-wrap: wrap;
  }
  .spg-row--multiple > div {
    flex-grow: 1;
    min-width: calc(25.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-row--multiple > div .spg-question__header--location--left {
    min-width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-row--multiple > div input {
    min-width: max(17 * var(--sjs-base-unit, var(--base-unit, 8px)), 100%);
    width: 0;
  }
  
  .spg-question__erbox {
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-question__erbox > div,
  sv-question-error > div {
    display: flex;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .spg-question__erbox-icon {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-size: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-repeat: no-repeat;
    background-position: 0 0;
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    white-space: normal;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.0336 15.4803L14.5736 1.45346C13.9936 0.487805 12.9936 0 12.0036 0C11.0136 0 10.0136 0.487805 9.43364 1.45346L0.973642 15.4803C-0.226358 17.4714 1.21364 20 3.54364 20H20.4536C22.7836 20 24.2236 17.4714 23.0236 15.4803H23.0336ZM21.3336 17.5112C21.2436 17.6605 20.9936 18.0189 20.4636 18.0189H3.54364C3.01364 18.0189 2.76364 17.6705 2.67364 17.5112C2.58364 17.3519 2.41364 16.9537 2.68364 16.5057L11.1436 2.47885C11.4036 2.04082 11.8336 1.99104 12.0036 1.99104C12.1736 1.99104 12.5936 2.04082 12.8636 2.47885L21.3136 16.5057C21.5836 16.9537 21.4136 17.3519 21.3236 17.5112H21.3336ZM13.0036 7.05824V12.0358C13.0036 12.5834 12.5536 13.0314 12.0036 13.0314C11.4536 13.0314 11.0036 12.5834 11.0036 12.0358V7.05824C11.0036 6.5107 11.4536 6.06272 12.0036 6.06272C12.5536 6.06272 13.0036 6.5107 13.0036 7.05824ZM13.0036 15.0224C13.0036 15.5699 12.5536 16.0179 12.0036 16.0179C11.4536 16.0179 11.0036 15.5699 11.0036 15.0224C11.0036 14.4749 11.4536 14.0269 12.0036 14.0269C12.5536 14.0269 13.0036 14.4749 13.0036 15.0224Z' fill='%23E60A3E'/%3E%3C/svg%3E");
  }
  
  .spg-question__erbox--location--bottom {
    margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .spg-restfull .spg-panel__content,
  .spg-masksettings .spg-panel__content {
    gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-restfull .spg-panel__content .spg-row,
  .spg-masksettings .spg-panel__content .spg-row {
    margin-top: 0;
  }
  
  .spg-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    box-sizing: border-box;
    width: 100%;
    height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border: none;
    box-shadow: inset 0 0 0 1px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    outline: none;
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  
  .spg-input::placeholder {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .spg-input:focus,
  .spg-input.spg-dropdown:focus,
  .spg-input.spg-dropdown:focus-within,
  .spg-input-container:focus-within {
    box-shadow: inset 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .spg-input:disabled,
  .spg-input:disabled::placeholder {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 0.25;
  }
  
  .spg-input[type=color] {
    padding-left: 0;
    padding-right: 0;
    min-width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-input__edit-button {
    position: relative;
    box-sizing: border-box;
    appearance: none;
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border: none;
    outline: none;
    width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    line-height: 0;
  }
  .spg-input__edit-button:focus, .spg-input__edit-button:hover {
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  .spg-input__edit-button:active {
    opacity: 0.5;
  }
  .spg-input__edit-button.spg-input__edit-button--disabled, .spg-input__edit-button:disabled {
    opacity: 0.25;
    background: var(--sjs-general-backcolor, var(--background, #fff));
  }
  .spg-input__edit-button svg {
    height: 24px;
    width: 24px;
    fill: rgba(0, 0, 0, 0.45);
  }
  
  .spg-input.spg-input--error {
    box-shadow: 0 0 0 1px inset var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .spg-input-container {
    display: flex;
    justify-content: space-between;
    cursor: default;
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    align-items: center;
    gap: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-shadow: 0 0 0 1px inset var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  }
  
  .spg-input-container--multiline {
    align-items: end;
    flex-direction: column;
    gap: 0;
    height: auto;
  }
  .spg-input-container--multiline sv-ng-question-comment {
    height: calc(9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-input-container--multiline .spg-input-container__input {
    box-sizing: border-box;
    resize: none;
  }
  
  .spg-input-container__input {
    flex-grow: 1;
    width: 100%;
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    outline: none;
    border: none;
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: transparent;
  }
  
  .spg-input-container__input:disabled {
    opacity: 0.25;
  }
  
  .spg-input-container__buttons-container {
    display: flex;
    gap: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-dropdown {
    border-radius: 0;
    display: flex;
    padding-right: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-position: right calc(1.625 * (var(--sjs-base-unit, var(--base-unit, 8px)))) top 50%, 0 0;
  }
  
  .spg-dropdown__filter-string-input {
    line-height: unset;
  }
  
  .spg-dropdown_chevron-button {
    line-height: normal;
  }
  
  .spg-dropdown-popup {
    height: 0;
  }
  
  .spg-comment {
    height: calc(9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    max-width: 100%;
    min-width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    min-height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-panel__title {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border: none;
    outline: none;
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    cursor: pointer;
    text-align: left;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    box-shadow: inset 0px -1px 0px var(--sjs-border-default, var(--border, #d6d6d6));
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-weight: 400;
    margin: 0;
  }
  
  .spg-panel__title:disabled,
  .spg-panel__title:disabled:hover {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    opacity: 0.25;
    cursor: default;
  }
  
  .spg-panel__title--collapsed:hover,
  .spg-panel__title--expandable:focus {
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .spg-panel__title--expanded {
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    font-weight: 600;
  }
  
  .spg-panel__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    box-shadow: inset 0px -1px 0px var(--sjs-border-default, var(--border, #d6d6d6));
  }
  
  .spg-panel__content .spg-row {
    width: 100%;
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-panel__content .spg-row--multiple {
    margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .sv-mobile-side-bar .spg-panel__content {
    padding: 0 calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-question__nopadding .spg-panel__content {
    padding: 0px;
    box-shadow: unset;
  }
  
  .spg-checkbox {
    cursor: pointer;
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-checkbox--disabled {
    cursor: default;
  }
  .spg-checkbox--disabled .spg-checkbox {
    cursor: default;
  }
  .spg-checkbox--disabled .spg-checkbox__caption {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .spg-checkbox__caption {
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-checkbox__rectangle {
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    box-sizing: border-box;
    width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    flex-shrink: 0;
    padding: 1px 1px;
  }
  
  .spg-checkbox:hover .spg-checkbox__rectangle {
    background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .spg-checkbox--disabled .spg-checkbox__rectangle:hover {
    background: var(--sjs-general-backcolor, var(--background, #fff));
  }
  
  .spg-checkbox__control:focus + .spg-checkbox__rectangle {
    outline: 2px solid var(--sjs-primary-backcolor, var(--primary, #293650));
    outline-offset: -2px;
  }
  
  .spg-checkbox .spg-checkbox__svg {
    display: none;
  }
  
  .spg-checkbox--checked .spg-checkbox__svg {
    display: block;
    width: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-repeat: no-repeat;
    background-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    fill: var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .spg-checkbox--disabled.spg-checkbox--checked .spg-checkbox__svg {
    fill: var(--sjs-border-default, var(--border, #d6d6d6));
  }
  
  .spg-checkbox__control {
    position: fixed;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
  }
  
  .spg-checkbox__hidden {
    display: none;
  }
  
  .spg-selectbase {
    border: none;
    padding: 0;
    margin: 0;
  }
  
  .spg-selectbase__label {
    display: flex;
    align-items: flex-start;
    gap: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .spg-checkbox .spg-question__description {
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0 0 calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-checkbox .spg-help-action use {
    pointer-events: none;
  }
  
  .spg-matrixdynamic__drag-element {
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-matrixdynamic__drag-element:hover {
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
  }
  .spg-matrixdynamic__drag-element:after {
    content: " ";
    display: block;
    height: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-drag-element__svg {
    width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    display: block;
    opacity: 0.5;
  }
  .spg-drag-element__svg use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .spg-matrixdynamic__drag-drop-ghost-position-top,
  .spg-matrixdynamic__drag-drop-ghost-position-bottom {
    position: relative;
  }
  
  .spg-matrixdynamic__drag-drop-ghost-position-top::after,
  .spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
    content: "";
    width: 100%;
    height: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: var(--sjs-secondary-backcolor, var(--secondary, #374462));
    position: absolute;
    left: 0;
  }
  
  .spg-matrixdynamic__drag-drop-ghost-position-top::after {
    top: 0;
  }
  
  .spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
    bottom: 0;
  }
  
  .spg-matrixdynamic__placeholder {
    padding: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    box-sizing: border-box;
  }
  .spg-matrixdynamic__placeholder .spg-matrixdynamic__add-btn {
    display: initial;
  }
  
  .spg-matrixdynamic__placeholder-text {
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .spg-matrixdynamic__add-btn {
    display: none;
    appearance: none;
    border: none;
    margin-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: transparent;
    color: var(--sjs-primary-backcolor, var(--primary, #293650));
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-weight: 600;
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-smiley-icon {
    width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin: var(--sjs-base-unit, var(--base-unit, 8px));
    border-radius: 100px;
    border: 1px solid var(--sjs-general-forecolor, var(--foreground, #161616));
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-smiley-icon svg {
    width: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    fill: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  
  .spg-table-wrapper {
    border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    border-bottom: none;
  }
  
  .spg-table {
    width: 100%;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    border-collapse: collapse;
  }
  .spg-table tr {
    border-bottom: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  }
  
  .spg-table__cell {
    padding: 0;
  }
  .spg-table__cell .spg-checkbox {
    margin: 0 calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-table__cell--detail-panel {
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  .spg-table__cell--detail-panel .spg-panel__content {
    box-shadow: none;
  }
  
  .spg-table__cell:not(.spg-table__cell--detail-panel):not(.spg-table__cell--actions):first-of-type {
    padding-left: 8px;
  }
  
  .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input {
    appearance: none;
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-shadow: none;
    border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-color: transparent;
    padding: var(--sjs-base-unit, var(--base-unit, 8px));
    height: auto;
  }
  .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input[type=color] {
    padding-right: 0;
  }
  .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input:focus, .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input:focus-within {
    box-shadow: inset 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #293650));
  }
  
  .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-dropdown_chevron-button {
    display: none;
  }
  
  .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-dropdown {
    width: max-content;
    padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-size: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background-position: right calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) top 50%, 0 0;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 15L17 10H7L12 15Z' fill='%2390909080'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }
  
  .spg-table__cell:not(.spg-table__cell--detail-panel):first-of-type .spg-text {
    padding-left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-table__cell--actions:first-of-type {
    width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-table__cell--actions:last-of-type .spg-action-bar {
    justify-content: flex-end;
  }
  
  .spg-table__cell--actions > .spg-matrixdynamic__drag-element {
    display: inline-block;
    margin-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    cursor: move;
  }
  
  .spg-table__cell--header {
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-weight: normal;
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
  .spg-table__cell--header span {
    display: none;
  }
  .spg-table__cell--header .sv-string-viewer {
    display: inline;
    white-space: unset;
  }
  
  .spg-table__question-wrapper {
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
  }
  
  .spg-question-group .spg-row {
    margin-top: 0;
  }
  .spg-question-group .spg-row:not(:first-of-type) {
    margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .spg-color-editor__color-input {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    left: 0;
    top: 100%;
    transform: translateY(-50%);
  }
  
  .spg-color-editor__color-item {
    display: flex;
    gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-color-editor__color-item .spg-color-editor__color-swatch {
    margin-left: 0;
  }
  
  .spg-color-editor__color-swatch {
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 3px;
    width: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.16);
    margin-left: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-color-editor__color-swatch .sv-svg-icon {
    position: absolute;
    width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: var(--sjs-general-backcolor, var(--background, #fff));
  }
  
  .spg-color-editor__color-swatch--disabled .sv-svg-icon {
    opacity: 0.25;
  }
  
  .spg-color-editor__color-swatch--default .sv-svg-icon {
    opacity: 1;
  }
  
  .spg-color-editor .spg-input__edit-button + * {
    margin-inline-end: calc(-0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-file-edit__choose-button {
    position: relative;
  }
  
  .spg-link {
    display: flex;
  }
  
  .sv-string-viewer .spg-link {
    display: initial;
    color: var(--sjs-primary-backcolor, var(--primary, #293650));
    font-weight: 600;
    text-decoration-line: underline;
  }
  
  .spg-theme-builder-root .sv-skeleton-element {
    min-height: 50px;
  }
  .spg-theme-builder-root .spg-paneldynamic__separator {
    border: none;
    margin: 0;
    height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-theme-builder-root .spg-panel__footer {
    justify-content: center;
    margin: var(--sjs-base-unit, var(--base-unit, 8px)) 0 0 0;
    padding: 0;
  }
  .spg-theme-builder-root .spg-paneldynamic__add-btn {
    margin: 0 auto;
  }
  .spg-theme-builder-root .spg-row--multiple .spg-spin-editor .spg-input-container__buttons-container {
    display: none;
  }
  .spg-theme-builder-root .spg-row--multiple .spg-question__header--location--left {
    min-width: auto;
  }
  .spg-theme-builder-root .spg-row--multiple > div {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - var(--sjs-base-unit, var(--base-unit, 8px)) * 0.5);
    min-width: auto;
  }
  .spg-theme-builder-root .spg-row--multiple > div input {
    min-width: 0;
  }
  .spg-theme-builder-root .spg-row--multiple > div .spg-question--location--left {
    height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    box-sizing: border-box;
  }
  .spg-theme-builder-root .spg-row--multiple > div .spg-question--location--left .spg-input {
    height: auto;
  }
  .spg-theme-builder-root .sv-button-group {
    overflow: hidden;
  }
  .spg-theme-builder-root .spg-question__header--location--left {
    width: max-content;
    flex: 0 0;
  }
  .spg-theme-builder-root .spg-question__header--location--left .spg-question__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .spg-theme-builder-root .spg-question__header--location--left .spg-question__title .sv-string-viewer {
    white-space: nowrap;
  }
  .spg-theme-builder-root .spg-nested-panel {
    padding-bottom: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-theme-builder-root .spg-nested-panel::after {
    content: " ";
    display: block;
    position: relative;
    left: calc(-4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    top: calc(5 * var(--sjs-base-unit, var(--base-unit, 8px)));
    width: calc(8 * var(--sjs-base-unit, var(--base-unit, 8px)) + 100%);
    height: 1px;
    background-color: var(--sjs-border-default, var(--border, #d6d6d6));
  }
  .spg-theme-builder-root .spg-nested-panel__content {
    padding: 0;
    box-shadow: none;
  }
  .spg-theme-builder-root .spg-nested-panel__content > .spg-row {
    margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-theme-builder-root .spg-nested-panel__content > .spg-row:first-of-type {
    margin-top: 0;
  }
  .spg-theme-builder-root .spg-panel-group__content {
    padding-bottom: 0;
  }
  .spg-theme-builder-root .spg-panel-group__content::after {
    content: none;
  }
  .spg-theme-builder-root .spg-panel-group__content > .spg-row {
    margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  .spg-theme-builder-root .spg-panel-group__content > .spg-row:first-of-type {
    margin-top: 0;
  }
  .spg-theme-builder-root .spg-panel-group__title,
  .spg-theme-builder-root .spg-nested-panel__title {
    width: 100%;
    margin: 0;
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    font-weight: normal;
    box-sizing: border-box;
  }
  .spg-theme-builder-root .spg-paneldynamic__panel-wrapper > .spg-nested-panel,
  .spg-theme-builder-root .spg-row:last-of-type > div > .spg-nested-panel {
    padding-bottom: 0;
  }
  .spg-theme-builder-root .spg-paneldynamic__panel-wrapper > .spg-nested-panel::after,
  .spg-theme-builder-root .spg-row:last-of-type > div > .spg-nested-panel::after {
    content: none;
  }
  .spg-theme-builder-root .spg-theme-group-caption {
    --default-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
    line-height: calc(1.5 * var(--default-bold-font-size));
    color: var(--sjs-general-dim-forecolor, rgba(0, 0, 0, 0.91));
    position: relative;
    top: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px)));
    padding: calc(1 * var(--sjs-base-unit, var(--base-unit, 8px))) 0;
    cursor: auto;
  }
  .spg-theme-builder-root .sv-theme-group_title-action .spg-action-button:hover,
  .spg-theme-builder-root .sv-theme-group_title-action .spg-action-button:focus {
    background-color: unset;
  }
  .spg-theme-builder-root .sd-panel__header.sd-element__header .spg-action-bar {
    pointer-events: none;
  }
  
  .sv-mobile-side-bar .spg-theme-builder-root .spg-nested-panel__content::after {
    width: calc(4 * var(--sjs-base-unit, var(--base-unit, 8px)) + 100%);
    left: calc(-2 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }
  
  .spg-boolean-wrapper--overriding.spg-boolean-wrapper--overriding {
    min-width: 0;
    flex: 0 0 max-content;
  }
  
  .spg-link-wrapper--overriding {
    align-self: end;
  }
  .spg-link-wrapper--overriding .svc-action-button {
    text-decoration: underline;
    padding: 0;
    border: none;
    margin: 0;
  }
  .spg-link-wrapper--overriding .svc-action-button:focus, .spg-link-wrapper--overriding .svc-action-button:hover {
    background-color: transparent;
  }
  
  .spg-editor--highlighted .spg-input,
  .spg-editor--highlighted .spg-input-container,
  .spg-editor--highlighted .spg-question--location--left,
  .spg-editor--highlighted .spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input {
    box-shadow: 0 0 0 2px var(--sjs-secondary-backcolor, var(--secondary, #374462));
  }
  .spg-editor--highlighted .spg-checkbox__control:focus + .spg-checkbox__rectangle,
  .spg-editor--highlighted .spg-matrixdynamic__content.spg-text__content {
    outline: 2px solid var(--sjs-secondary-backcolor, var(--secondary, #374462));
    outline-offset: -2px;
  }
  .spg-editor--highlighted .spg-checkbox__control:focus + .spg-checkbox__rectangle .spg-input,
  .spg-editor--highlighted .spg-matrixdynamic__content.spg-text__content .spg-input {
    box-shadow: none;
  }
  .spg-editor--highlighted .svc-action-button {
    background-color: var(--sjs-secondary-backcolor-light, var(--secondary-light, rgba(255, 152, 20, 0.25)));
  }
  .spg-editor--highlighted .sv-button-group {
    box-shadow: 0 0 0 1px var(--sjs-secondary-backcolor, var(--secondary, #374462));
    border-color: var(--sjs-secondary-backcolor, var(--secondary, #374462));
  }
  .spg-editor--highlighted .spg-checkbox__control + .spg-checkbox__rectangle {
    outline: 2px solid var(--sjs-secondary-backcolor, var(--secondary, #374462));
    outline-offset: -2px;
  }
  
  .spg-search-editor_container {
    --sjs-general-forecolor: rgba(0, 0, 0, 0.91);
    --sjs-general-forecolor-light: rgba(0, 0, 0, 0.45);
  }
  
  .spg-search-editor_container {
    position: absolute;
    border-bottom: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    width: calc(100% - 4 * var(--sjs-base-unit, var(--base-unit, 8px)));
    display: flex;
    align-items: center;
  }
  
  .spg-search-editor_input {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
    font-family: var(--sjs-font-family, var(--font-family));
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    font-style: normal;
    font-weight: 600;
    line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    background: transparent;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    padding-inline-start: var(--sjs-base-unit, var(--base-unit, 8px));
    padding-inline-end: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-creator--mobile .spg-search-editor_input {
    font-size: max(16px, calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  }
  
  .spg-search-editor_input::placeholder {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .spg-search-editor_toolbar {
    display: flex;
    gap: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
  }
  
  .spg-search-editor_toolbar .sv-action-bar {
    gap: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin: 0;
    flex-shrink: 0;
  }
  
  .spg-search-editor_toolbar .sv-action--hidden {
    display: none;
  }
  
  .spg-search-editor_toolbar .spg-search-editor_bar-item {
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: min-content;
    margin: 0;
  }
  
  .spg-search-editor_toolbar-counter {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 600;
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    display: flex;
    flex-shrink: 0;
  }
  
  .spg-search-editor_search-icon {
    width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-search-editor_search-icon .sv-svg-icon {
    width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-search-editor_search-icon .sv-svg-icon use {
    fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  }
  
  .spg-root-modern {
    position: relative;
    width: 100%;
    border-right: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    box-sizing: border-box;
  }
  .spg-root-modern .sd-row--fade-out,
  .spg-root-modern .sd-row--fade-in {
    animation: none;
  }
  .spg-root-modern .sd-element-wrapper--fade-out,
  .spg-root-modern .sd-element-wrapper--fade-in {
    animation: none;
  }
  .spg-root-modern .sd-element__content--fade-in,
  .spg-root-modern .sd-element__content--fade-out {
    animation: none;
  }
  .spg-root-modern .sd-paneldynamic__panel-wrapper--fade-in,
  .spg-root-modern .sd-paneldynamic__panel-wrapper--fade-out,
  .spg-root-modern .sd-paneldynamic__panel-wrapper--fade-out-left,
  .spg-root-modern .sd-paneldynamic__panel-wrapper--fade-out-right,
  .spg-root-modern .sd-paneldynamic__panel-wrapper--fade-in-left,
  .spg-root-modern .sd-paneldynamic__panel-wrapper--fade-in-right {
    animation: none;
  }
  .spg-root-modern .sd-table__row--fade-out,
  .spg-root-modern .sd-table__row--fade-in {
    animation: none;
  }
  
  .spg-container {
    width: 100%;
  }
  .spg-container * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
  
  .spg-container.spg-container_search {
    height: 100%;
  }
  
  .spg-container_search .spg-root-modern {
    top: calc(7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-top: 1px;
    height: calc(100% - 1px - 7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    overflow: auto;
  }
  
  .sv-popup--modal.sv-property-editor .sv-popup__container .sv-popup_shadow {
    background-color: transparent;
  }
  .sv-popup--modal.sv-property-editor .sv-popup__container .sd-root-modern {
    background-color: transparent;
  }
  .sv-popup--modal.sv-property-editor .sv-popup__container .sd-page {
    margin: 0;
    padding: 0;
  }
  .sv-popup--modal.sv-property-editor .sv-popup__container .sv-components-column--expandable {
    min-width: calc(78 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .sv-popup--modal .sv-popup__footer {
    padding-bottom: 2px;
  }
  .sv-popup--modal .sd-body,
  .sv-popup--modal .sl-body {
    min-width: calc(78 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sv-popup.sv-property-editor .sd-root-modern {
    --sd-mobile-width: 0;
  }
  
  .svc-object-selector .sv-popup__container {
    margin-left: var(--sjs-base-unit, var(--base-unit, 8px));
    margin-right: var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-object-selector__content {
    position: relative;
    height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
  
  .spg-title .spg-action-bar {
    margin-top: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-bottom: calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    margin-right: 1px;
  }
  
  .spg-body {
    width: 100%;
  }
  
  .spg-body--empty {
    margin: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .spg-title-toolobar--single-help-action {
    justify-content: flex-start;
    padding-left: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-title-toolobar--single-help-action .spg-help-action {
    flex-grow: 1;
  }
  .spg-title-toolobar--single-help-action .spg-help-action .spg-action-button--icon {
    opacity: 0.5;
    padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .spg-title-toolobar--single-help-action .spg-help-action .spg-action-button--icon:focus,
  .spg-title-toolobar--single-help-action .spg-help-action .spg-action-button--icon:hover {
    opacity: 1;
  }
  .spg-title-toolobar--single-help-action .spg-help-action .spg-action-button:active {
    opacity: 0.5;
  }
  .spg-title-toolobar--single-help-action .spg-help-action svg {
    fill: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
  }
  .svc-text {
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: normal;
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
  }
  
  .svc-text--normal {
    font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-text--small {
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-text--bold {
    font-weight: 600;
  }
  .svc-flex-container {
    display: flex;
  }
  
  .svc-flex-row {
    display: flex;
    flex-direction: row;
    background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  }
  
  .svc-full-container {
    height: 100%;
    width: 100%;
  }
  
  .svc-flex-row.svc-full-container {
    height: calc(60 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    min-height: calc(60 * (var(--sjs-base-unit, var(--base-unit, 8px))));
    flex-grow: 1;
    position: relative;
  }
  
  .svc-flex-row__element--growing {
    flex: 1;
    overflow: auto;
  }
  
  .svc-flex-column {
    display: flex;
    flex-direction: column;
  }
  
  .sv-action-bar--default-size-mode .sv-action-bar-separator {
    height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sv-action-bar--small-size-mode .sv-action-bar-separator {
    height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .svc-creator--mobile .sv-action-bar-separator {
    height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  
  .sv-action-bar--small-size-mode .sv-action-bar-item {
    font-weight: 600;
    align-items: center;
  }
  
  .svc-top-bar {
    display: flex;
    background: var(--sjs-general-backcolor, var(--background, #fff));
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px var(--sjs-border-default, var(--border, #d6d6d6));
  }
  .svc-top-bar .svc-toolbar-wrapper {
    flex: 0 0 auto;
    display: flex;
  }
  .svc-top-bar .svc-toolbar-wrapper .sv-action-bar {
    padding: 0 var(--sjs-base-unit, var(--base-unit, 8px));
    justify-content: flex-end;
  }
  .svc-top-bar .svc-toolbar-wrapper .sv-action-bar-separator {
    display: none;
  }
  .svc-top-bar .svc-toolbar-wrapper .sv-action-bar > .sv-action:not(.sv-action--hidden) ~ .sv-action .sv-action-bar-separator {
    display: inline-block;
  }
  .svc-top-bar .svc-toolbar-wrapper .sv-action .sv-action-bar-item {
    margin: 0 var(--sjs-base-unit, var(--base-unit, 8px));
  }
  
  .svc-footer-bar .svc-toolbar-wrapper {
    height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  }
  .svc-footer-bar .svc-toolbar-wrapper .sv-action-bar {
    justify-content: center;
    padding: 0;
    width: 100%;
    height: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
    background: var(--sjs-general-backcolor, var(--background, #fff));
    border-top: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
    box-sizing: border-box;
  }
  
  /*# sourceMappingURL=survey-creator-core.fontless.css.map*/